<template>
    <div class="modal-mask" @click="fecharModal">
        <div class="jm margem" @click.stop>
            <div style="display: flex; justify-content: center">
                <h2>{{ protocoloEditado.nome }}</h2>
            </div>
            <br>
            <fieldset class="margem">
                <div class="grid-2">
                    <div>
                        <label>Data de termino do projeto</label>
                        <input :class="{ shake: disabled }" v-model="pedidoEncerramento.dtConclusao" id="dtConclusao"
                            type="date">
                    </div>
                    <div class="grid-2">
                        <div>
                            <label>Custo previsto</label>
                            <money3 :class="{ shake: disabled }" v-bind="config" id="custoEstimado"
                                v-model="pedidoEncerramento.custoEstimado"
                                style="width: 8rem; font-size: 20px; background-color: transparent;">
                            </money3>
                        </div>
                        <div>
                            <label>Custo Real</label>
                            <money3 :class="{ shake: disabled }" v-bind="config" id="custoReal"
                                v-model="pedidoEncerramento.custoReal"
                                style="width: 8rem; font-size: 20px; background-color: transparent;">
                            </money3>
                        </div>
                    </div>
                    <div class="col-2">
                        <label>
                            Evidencias de alcance
                        </label>
                        <textarea :class="{ shake: disabled }" id="evidenciaAlcance"
                            v-model="pedidoEncerramento.evidenciaAlcance"></textarea>
                    </div>
                    <div class="col-2">
                        <label>Escopo alcançado</label>
                        <textarea :class="{ shake: disabled }" id="escopoAlcancado"
                            v-model="pedidoEncerramento.escopoAlcancado"></textarea>
                    </div>
                </div>
            </fieldset>
            <div style="align-content: end; margin-left: 1rem; display: flex;">
                <button @click="solicitarEncerramento()">
                    Solicitar encerramento</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Money3Component } from "v-money3";
import service from '@/services/serviceEncerramentoProtocolo'
export default {
    name: 'ModalEncerrarProtocolo',

    props: {
        protocoloEditado: {
            required: true
        },
    },

    components: { money3: Money3Component },

    data() {
        return {
            pedidoEncerramento: {
                protocolo_id: this.protocoloEditado.id,
                dtConclusao: null,
                evidenciaAlcance: null,
                custoEstimado: null,
                custoReal: null,
                escopoAlcancado: null,
                conclusao: null,
                resultadoObtido: null,
                status: 'Aguardando aprovação',
            }
        }
    },
    methods: {
        async solicitarEncerramento() {
            var response = await service.solicitarEncerramento(this.pedidoEncerramento)
            if (response) {
                this.fecharModal()
            }
        },
        fecharModal() {
            this.$emit('fecharModal')
        }
    },
}
</script>

<style lang="scss" scoped></style>