<template>
    <main id="main">
        <section v-if="PCM">
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ PCM.codigo + ' - ' + PCM.nome }}</h2>
                </div>
            </div>
            <div class="margem container">
                <PcmComponent :idPCM="idPCM"/>
            </div>
        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { api } from "roboflex-thalamus-request-handler";
import PcmComponent from "@/components/PCM/pcmComponent.vue";

export default {
    name: "VisualizaçãoPCM",
    components: {
        PcmComponent,
    },
    props: {
        idPCM: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            PCM: null,
        };
    },

    created() {
        this.perfil = 1
        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id,

            this.getPCM()
    },

    methods: {
        getPCM() {
            api.get(`pcm/buscar/${this.idPCM}`)
                .then(response => {
                    this.PCM = response.data
                    if (this.PCM.nivel == 'Estratégico') {
                        this.PCM.responsavel_id = 15
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
};
</script>

<style scoped></style>
