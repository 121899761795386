<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita"><button @click="criarProtocolo(5)"
                            class="acao-secundaria">Adicionar</button></div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar" v-model="protocoloSelecionado"
                                @input="filtrarProtocolos()" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>{{ tipoPorExtenso }}</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th scope="col">Código</th>
                                <th>Criador</th>
                                <th scope="col">Nome</th>
                                <th scope="col">
                                    Status
                                    <v-menu :close-on-content-click="false">
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                style="width: 1.5rem; height: 1.5rem; box-shadow: none; font-size: 12px"
                                                class="botaoAdicionarSprint acao-secundaria" icon="mdi-filter-variant"
                                                v-bind="props">
                                            </v-btn>
                                        </template>
                                        <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                            <v-list-item>
                                                <label>Exibir Apenas</label>
                                                <select v-model="statusParaFiltar" @change="filtarStatus">
                                                    <option :value="null">Todos</option>
                                                    <option style="color: #00B6B3;">Pendente</option>
                                                    <option style="color: var(--cor-ok);">Em andamento</option>
                                                    <option style="color: var(--cor-sucesso);">Concluído</option>
                                                    <option style="color: #FFA500;">Suspenso</option>
                                                </select>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </th>
                                <th scope="col">Data de Registro</th>
                                <th scope="col">Responsável</th>
                                <th scope="col">Setor Envolvido</th>
                                <th></th>
                            </tr>
                            <tr style="text-align: center;" v-for="item in listaprotocoloFiltrada" :key="item.id"
                                @click="verProtocolo(item.id)">
                                <td style="vertical-align: middle;">
                                    {{ item.codigo }}
                                </td>
                                <td style="vertical-align: middle">
                                    {{ item.criador ?? '-' }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.nome }}
                                </td>
                                <td style="vertical-align: middle" @click.stop>
                                    <span class="alinha-v alinha-centro" :style="{
                                        color:
                                            item.status == 'Aprovado' || item.status == 'Pendente'
                                                ? '#00B6B3'
                                                : item.status == 'Em andamento'
                                                    ? 'var(--cor-ok)'
                                                    : item.status == 'Concluído'
                                                        ? 'var(--cor-sucesso)'
                                                        : '#FFA500',
                                    }">{{ item.status }} <a @click="abrirModalEncerramento(item)"
                                            v-if="item.status !== 'Concluído'"
                                            :title="item.encerrado == 0 ? 'Encerramento Solicitado' : 'Solicitar Encerramento'"
                                            :style="{ 'opacity': (item.encerrado == 0 ? '0.5' : '1') }"
                                            class="icone-flag"></a></span>
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.dtInicio ?
                                        `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                        : '-' }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.responsavel_nome }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.setor_nome ? item.setor_nome : '-' }}
                                </td>
                                <td @click.stop><i style="font-size: 20px;" title="Editar Participantes"
                                        @click="protocoloEditado = item, showModal = true, tipoModal = 2"
                                        class="bi bi-people-fill"></i></td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="!protocolos" class="loading" id="loading">
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <ModalEncerrarProtocolo :protocoloEditado="protocoloEditado" v-if="showModal && tipoModal == 1"
        @fecharModal="showModal = false, this.getProtocolos();" />
    <ModalParticipantesProtocolo :protocolo="protocoloEditado" v-if="showModal && tipoModal == 2"
        @salvar="salvarParticipantes" @fecharModal="showModal = false" />
</template>
<script>
import ModalEncerrarProtocolo from "@/components/Modais/ModalEncerrarProtocolo.vue";
import { api } from "roboflex-thalamus-request-handler";
import serviceFunções from "@/services/serviceFunções";
import ModalParticipantesProtocolo from "@/components/Modais/ModalParticipantesProtocolo.vue";

export default {
    name: "ControleProtocolo",

    components: {
        ModalEncerrarProtocolo,
        ModalParticipantesProtocolo
    },

    props: {
        tipo: {
            required: true
        },
    },

    watch: {
        tipo() {
            this.getProtocolos();
        }
    },

    computed: {
        tipoPorExtenso() {
            var nome = ''
            switch (this.tipo) {
                case '1':
                    nome = 'Tratativa de Não Conformidade'
                    break;
                case '3':
                    nome = 'Resposta de Avaliação de Produto'
                    break;
                case '4':
                    nome = 'Ordem de Serviço'
                    break;
                case '5':
                    nome = 'Plano de Ação'
                    break;
                default:
                    nome = ''
            }
            return nome
        }
    },

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        return {
            formatarDataHora
        };
    },

    data() {
        return {
            showModal: false,
            tipoModal: null,
            protocoloEditado: {},
            statusParaFiltar: null,
            listaprotocoloFiltrada: null,
            protocoloSelecionado: null,
            protocolos: null,
            tipoProtocolo: sessionStorage.getItem('tipoProtocolo')
        }
    },

    mounted() {
        this.getProtocolos()
    },

    methods: {
        salvarParticipantes(lista) {
            this.editarProtocolo(this.protocoloEditado.id, 'participantes', lista)
        },
        abrirModalEncerramento(item) {
            if (item.encerrado == null) {
                this.protocoloEditado = item;
                this.showModal = true;
                this.tipoModal = 1
            }
        },
        filtarStatus() {
            if (this.statusParaFiltar) {
                this.listaprotocoloFiltrada = this.protocolos.filter(item => item.status == this.statusParaFiltar)
            }
        },

        editarProtocolo(idProtocolo, itemAlterado, novoValor) {
            api.post(`protocolo/atualizar/${idProtocolo}`, {
                [itemAlterado]: novoValor,
            })
                .then(() => {
                    this.getProtocolos()
                })
        },

        getProtocolos() {
            api.get(`/protocolo/listar?tipo_id=${this.tipo}`)
                .then((response) => {
                    this.protocolos = response.data
                    this.protocolos = this.protocolos.sort((a, b) => {
                        if (a.codigo < b.codigo) return 1;
                        if (a.codigo > b.codigo) return -1;
                        return 0;
                    });

                    this.filtrarProtocolos()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        filtrarProtocolos() {
            this.statusParaFiltar = null
            if (!this.protocoloSelecionado) {
                this.listaprotocoloFiltrada = this.protocolos;
            } else {
                const textoLowerCase = this.protocoloSelecionado.toLowerCase();
                this.listaprotocoloFiltrada = this.protocolos.filter(projeto => {
                    return Object.values(projeto).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        criarProtocolo() {
            this.$router.push({ name: 'criaçãoProtocolo', params: this.tipo })
        },

        verProtocolo(idProtocolo) {
            this.$router.push({ name: 'visualizaçãoProtocolo', params: { idProtocolo } })
        }
    }

}
</script>

<style scoped>
.header {
    cursor: pointer;
}
</style>