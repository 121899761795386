<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita"></div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar Rotina" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Rotina</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="submit">
                    <v-btn @click="mostrarModal = true" icon="mdi mdi-plus"></v-btn>
                </div>
                <br>

                <div class="bloco margem">
                    <table class="tabela">
                        <tbody style="cursor: pointer;">
                            <tr>
                                <th>Grupos de Discussão</th>
                                <th>Participantes</th>
                                <th></th>
                            </tr>
                            <tr v-for="item in grupos" :key="item.id" @click="visualizarGrupo(item)">
                                <td>{{ item.nome }}</td>
                                <td>
                                    {{item.participantes.length > 0 ? item.participantes.map(p =>
                                    nomeEsobrenome(p.nome)).join(', ') : '-'}}
                                </td>
                                <td style="display: flex; justify-content: center;">
                                    <div>
                                        <a @click.stop="removerGrupo(item.id)" title="Clique para excluir grupo"
                                            class="icone-lixeira"></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- MODAL -->
            <div v-if="mostrarModal" class="modal-mask">
                <div class="jm margem">
                    <!-- <div class="modal-container"> -->
                    <div class="modal-header">
                        <h3>Adicionar Grupo</h3>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div>
                                <label>Nome do Grupo</label>
                                <input v-model="novoGrupoNome" type="text">
                            </div>
                            <br>
                            <div>
                                <label>Participantes para adicionar</label>
                                <input v-model="searchQueryUser" @focus="abrirListaUser" @blur="fecharListaUser"
                                    placeholder="Pesquisar Usuário" type="text" style="width: 100%;" />
                                <div v-if="listaAbertaUser" class="dropdown-list" style="cursor: pointer;">
                                    <ul>
                                        <li v-for="user in filteredUsers" :key="user.nomeCompleto">
                                            <div @click="toggleUser(user)" style="padding: 0 .5rem;"
                                                :class="{ 'disabled': isUserInGroup(user) }"> {{ user.nomeCompleto }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                            <div class="selected-users">
                                <label>Participantes: </label>
                                <div v-for="(user, index) in participantesSelecionados" :key="index" class="user-tag">
                                    {{ user.nomeCompleto }} <i class="fa-solid fa-circle-xmark"
                                        @click="desassociarUsuario(user)" style="color: red;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="submit">
                            <button @click="criarNovoGrupo">Criar</button>
                            <button @click="mostrarModal = false" class="acao-secundaria">Cancelar</button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <!-- END MODAL -->
        </section>
    </main>
</template>
<script>
import serviceRotina from '@/services/serviceRotina';
import { consultarSetores } from '@/services/usuario-setor';
import serviceFunções from '@/services/serviceFunções';
import { sso } from "roboflex-thalamus-sso-lib";

export default {
    name: "GruposView",

    data() {
        return {
            grupos: [],
            pessoas: [],
            participantesSelecionados: [],
            searchQueryUser: "",
            listaAbertaUser: false,
            novoGrupoNome: null,
            mostrarModal: false,
            idUsuario: null
        };
    },

    computed: {
        filteredUsers() {
            return this.pessoas.filter(user =>
                user.nomeCompleto.toLowerCase().includes(this.searchQueryUser.toLowerCase())
            );
        },
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id
    },

    async mounted() {
        this.pessoas = (await consultarSetores()).usuarios;
        this.buscarGrupos()
    },

    methods: {
        nomeEsobrenome(nome) {
            return serviceFunções.nomeEsobrenome(nome)
        },
        async buscarGrupos() {
            this.grupos = await serviceRotina.getGrupos()
        },
        async criarNovoGrupo() {
            var payload = {
                tipo_id: 6,
                nome: this.novoGrupoNome,
                responsavel_id: this.idUsuario,
                dtInicio: new Date().toISOString().split('T')[0],
                participantes: this.participantesSelecionados.map(item => item.id)
            }
            const response = await serviceRotina.criarGrupo(payload)
            if (response) {
                this.buscarGrupos()
                this.mostrarModal = false
            }
        },

        abrirListaUser() {
            this.listaAbertaUser = true;
        },

        fecharListaUser() {
            setTimeout(() => {
                this.listaAbertaUser = false;
            }, 200);
        },

        filtrarUsers() {
            if (!this.searchQueryUser) {
                this.listaAbertaUser = false;
            } else {
                this.listaAbertaUser = true;
            }
        },

        toggleUser(user) {
            if (!this.isUserInGroup(user)) {
                this.participantesSelecionados.push(user);
            }
            this.searchQueryUser = ""
        },

        isUserInGroup(user) {
            return this.participantesSelecionados.some(p => p.id === user.id);
        },

        desassociarUsuario(user) {
            this.participantesSelecionados = this.participantesSelecionados.filter(p => p.nome !== user.nome);
        },

        visualizarGrupo(item) {
            this.$router.push({ name: 'GrupoView', params: { id: item.id } });
        },

        removerGrupo(id) {
            this.grupos = this.grupos.filter(grupo => grupo.id !== id);
            serviceRotina.deletarGrupo(id)
        }
    }
}
</script>
<style scoped>
.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.jm {
    min-width: 30rem;
}

.modal-container {
    background-color: var(--cor-bg);
    padding: 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;

}

.dropdown-list {
    background-color: var(--cor-bg);
    z-index: 99999;
    max-height: 20rem;
    overflow: auto;
    position: absolute;
    width: 20.5rem;
    border: 1px solid var(--cor-separador);
}
</style>
