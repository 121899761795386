<template>
    <div>
        <div class="bloco margem">
            <div class="grid-1">
                <div>
                    <table class="tabela listaMateriaisEFerramentas">
                        <tr>
                            <th>Item</th>
                            <th>Quantidade - Unidade</th>
                            <th>Status</th>
                            <th>Data da criação</th>
                            <th>Data limite de recebimento</th>
                            <th>Data do recebimento</th>
                            <th>Observação</th>
                            <th></th>
                        </tr>
                        <tr v-for="material in materiais" :key="material.id">
                            <td>{{ material.material.cod }} - {{ material.material.desc }}</td>
                            <td>
                                <input type="number" v-model="material.qtd" placeholder="Quantidade" min="1"
                                    style="width: 70px; height: 3rem;"
                                    @focusout="atualizarMaterial(material.id, 'qtd', material.qtd)" />
                                <select v-model="material.unidade_id"
                                    @change="atualizarMaterial(material.id, 'unidade_id', material.unidade_id)"
                                    style="margin-inline: 2px; width: fit-content;">
                                    <option :value="1">UN</option>
                                    <option :value="2">Metro</option>
                                    <option :value="3">Litro</option>
                                    <option :value="4">Kg</option>
                                </select>
                            </td>
                            <td>
                                <select v-model="material.status" style="width: fit-content;"
                                    @change="atualizarMaterial(material.id, 'status', material.status)">
                                    <option hidden></option>
                                    <option>Aguardando Aprovação</option>
                                    <option>Recebido</option>
                                </select>
                            </td>
                            <td><input type="date" v-model="material.dt_criacao"
                                    @change="atualizarMaterial(material.id, 'dt_criacao', material.dt_criacao)"></td>
                            <td><input type="date" v-model="material.dt_limite_recebimento"
                                    @change="atualizarMaterial(material.id, 'dt_limite_recebimento', material.dt_limite_recebimento)">
                            </td>
                            <td><input type="date" v-model="material.dt_recebimento"
                                    @change="atualizarMaterial(material.id, 'dt_recebimento', material.dt_recebimento)">
                            </td>
                            <td><textarea v-model="material.observacao"
                                    @focusout="atualizarMaterial(material.id, 'observacao', material.observacao)"></textarea>
                            </td>
                            <td>
                                <button @click="desassociarMaterial(material.id)">Excluir</button>
                            </td>
                        </tr>
                    </table>
                    <div>
                        <input style="width: 400px" v-model="searchQueryMaterial" @focus="abrirListaMaterial"
                            @input="filtrarMateriais" @blur="fecharListaMaterial" @click="filtrarMateriais"
                            placeholder="Pesquisar Material" type="text" />
                        <div v-if="listaAbertaMaterial"
                            style="background-color: var(--cor-bg); z-index: 99999; max-height: 20rem; overflow: auto; position: absolute; width: 20.5rem; border: 1px solid var(--cor-separador);">
                            <ul style="list-style: none; padding: .5rem;">
                                <li v-for="material in filteredMateriais" :key="material.id" style="cursor: pointer;">
                                    <div @click="associarMaterial(material)">{{ material.desc }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import serviceProdutos from '@/services/serviceProdutos'
export default {

    props: {
        tipo: {
            type: String,
            required: true
        },
        idPai: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            searchQueryMaterial: "",
            filteredMateriais: [],
            listaAbertaMaterial: false,
            mostrarSolicitarProduto: false,
            materiais: [],
            itemEditado: {},
        };
    },

    methods: {
        async carregarListadeMateriais() {
            try {
                const payload = {
                    tipo: "projeto",
                    tipo_id: this.idPai
                };
                const response = await serviceProdutos.listarMateriaisProjeto(payload);

                this.materiais = response.data.map(item => ({
                    ...item,
                    dt_criacao: item.dt_criacao ? item.dt_criacao.split(" ")[0] : null,
                    dt_limite_recebimento: item.dt_limite_recebimento ? item.dt_limite_recebimento.split(" ")[0] : null,
                    dt_recebimento: item.dt_recebimento ? item.dt_recebimento.split(" ")[0] : null,
                }));


            } catch (error) {
                console.error("Erro ao carregar materiais:", error);
            }

        },
        abrirListaMaterial() {
            this.listaAbertaMaterial = true;
        },
        filtrarMateriais() {
            const query = this.searchQueryMaterial.toLowerCase();
            this.filteredMateriais = this.produtos.filter((material) =>
                material.desc.toLowerCase().includes(query)
            );

            this.mostrarSolicitarProduto = this.filteredMateriais.length === 0 && this.searchQueryMaterial.trim() !== "";
        },
        fecharListaMaterial() {
            setTimeout(() => {
                this.listaAbertaMaterial = false;
                this.filteredMateriais = [];
                this.mostrarSolicitarProduto = false;
            }, 200);
        },
        async atualizarMaterial(id, itemEditado, valor) {
            var payload = {
                [itemEditado]: valor
            }
            try {
                serviceProdutos.atualizaMaterial(id, payload)

            } catch (error) {
                console.error(error)
            }
        },
        async associarMaterial(material) {

            const payload = {
                tipo: "projeto", // tipo 'plano_acao','projeto'
                tipo_id: this.idPai, // id do projeto ou id do plano de acao
                material_cod: material.produto_cod
            };

            var response = await serviceProdutos.gravarMateriaisProjeto(payload)
            if (response) {
                this.carregarListadeMateriais()
            }
        },

        desassociarMaterial(id) {
            this.materiais = this.materiais.filter(
                (m) => m.id !== id
            );

            serviceProdutos.deletarMaterial(id)
        },

        async obterProdutos() {
            try {
                const response = await serviceProdutos.getProdutos();
                this.produtos = response;
            } catch (error) {
                console.error("Erro ao carregar produtos:", error);
            }
        },

    },
    async mounted() {
        this.obterProdutos();
        this.carregarListadeMateriais()
    }
}
</script>
<style scoped>
td {
    text-align: center !important;
    justify-content: center !important;
}

.listaMateriaisEFerramentas {
    max-height: 20rem;
    overflow: auto;
}
</style>
