<template>
    <div class="bloco margem grid-3" style="min-height: 27vw;">
        <div class="colunaKanban" style="border-color: rgb(255, 145, 0)">
            <h3>A iniciar</h3>
            <draggable v-model="pendentes" group="kanban" item-key="codigo" class="containerTarefa" @change="(event) => atualizarStatus(event, '0')">
                <template #item="{ element }">
                    <div class="tarefa">
                        <label class="tituloTarefa">{{ element.codigo }}</label>
                        <p>{{ element.comentario }}</p>
                        <div class="rodapeTarefa">
                            <div>
                                <label>Responsável</label>
                                <span> {{ element.responsavel ?? '-' }} </span>
                            </div>
                            <div>
                                <label>HP</label>
                                <span> {{ element.HP ?? '0' }} </span>
                            </div>
                            <div>
                                <label>Término</label>
                                <span> {{ element.dtFim ? formatDate(element.dtFim) : '-' }} </span>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="colunaKanban" style="border-color: rgb(0, 47, 255)">
            <h3>Em andamento</h3>
            <draggable v-model="emAndamento" group="kanban" item-key="codigo" class="containerTarefa"
                @change="(event) => atualizarStatus(event, '1')">
                <template #item="{ element }">
                    <div class="tarefa">
                        <label class="tituloTarefa">{{ element.codigo }}</label>
                        <p>{{ element.comentario }}</p>
                        <div class="rodapeTarefa">
                            <div>
                                <label>Responsável</label>
                                <span> {{ element.responsavel ?? '-' }} </span>
                            </div>
                            <div>
                                <label>HP</label>
                                <span> {{ element.HP ?? '0' }} </span>
                            </div>
                            <div>
                                <label>Término</label>
                                <span> {{ element.dtFim ? formatDate(element.dtFim) : '-' }} </span>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="colunaKanban" style="border-color: rgb(0, 192, 0);">
            <h3>Concluído</h3>
            <draggable v-model="concluidas" group="kanban" item-key="codigo" class="containerTarefa" @change="(event) => atualizarStatus(event, '5')">
                <template #item="{ element }">
                    <div class="tarefa">
                        <label class="tituloTarefa">{{ element.codigo }}</label>
                        <p>{{ element.comentario }}</p>
                        <div class="rodapeTarefa">
                            <div>
                                <label>Responsável</label>
                                <span> {{ element.responsavel ?? '-' }} </span>
                            </div>
                            <div>
                                <label>HP</label>
                                <span> {{ element.HP ?? '0' }} </span>
                            </div>
                            <div>
                                <label>Término</label>
                                <span> {{ element.dtFim ? formatDate(element.dtFim) : '-' }} </span>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
    components: {
        draggable
    },
    props: {
        tarefas: {
            Required: true
        }
    },
    data() {
        return {
            pendentes: [],
            emAndamento: [],
            concluidas: [],
        }
    },
    methods: {
        atualizarStatus(event, status) {
            if(event.added){
                this.$emit('atualizarTarefa', event.added.element.id, 'status', status)
            }
        },
        formatDate(date) {
            if (date != '-') {
                const dia = date.split('-')[2]
                const mes = date.split('-')[1]
                const ano = date.split('-')[0]
                return `${dia}/${mes}/${ano}`
            } else {
                return date
            }
        }
    },
    mounted() {
        this.pendentes = this.tarefas.filter(tarefa => tarefa.status.includes("0"));
        this.emAndamento = this.tarefas.filter(tarefa =>
            ["1", "2", "3", "4"].some(status => tarefa.status.includes(status))
        );
        this.concluidas = this.tarefas.filter(tarefa => tarefa.status.includes("5"));
    },
}
</script>
<style scoped>
.containerTarefa {
    min-height: 100%;
}

.colunaKanban {
    text-align: center;
    border: 1px solid var(--cor-separador);
    border-radius: 12px;
    padding: .5rem 1rem;
}

.tituloTarefa {
    font-size: 16px;
    font-weight: bold;
}

.tarefa {
    border: 1px solid var(--cor-separador);
    padding: .5rem;
    border-radius: 12px;
    margin-bottom: .5rem;
}

.rodapeTarefa {
    display: flex;
    gap: 5px;

    label {
        font-weight: bold;
        margin: 0 !important;
    }

    div {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        border: 1px solid var(--cor-separador);
        border-radius: 12px;
        padding: 2px 6px;
    }
}
</style>