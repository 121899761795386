<template>
    <div class="titulo">
        <div class="margem">
            <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                    class="icone-voltar m-d"></a></div>
            <h2>{{ nome }}<span @click="loadPdf"
                    :style="{ 'cursor': (this.pcm_codigo !== 'null') ? 'pointer' : 'default' }">{{ pcm_codigo == null ?
                        '' : ` - ${pcm_codigo}` }} <i v-if="pcm_codigo !== null" class="bi bi-file-earmark-pdf"></i></span>
            </h2>
        </div>
    </div>
    <div class="margem">
        <div class="container">
            <div class="abas">
                <a @click="aba = 1, salvarAba(1)" :class="aba == 1 ? 'ativo' : ''">Cadastro do Projeto</a>
                <a @click="aba = 2, salvarAba(2)" :class="aba == 2 ? 'ativo' : ''">Projeto</a>
                <!-- <a @click="aba = 4, salvarAba(4)" :class="aba == 4 ? 'ativo' : ''">Desenvolvimento de Produto</a>
                <a @click="aba = 3, salvarAba(3)" :class="aba == 3 ? 'ativo' : ''">Lista de Materiais</a>
                <a @click="aba = 5, salvarAba(5)" :class="aba == 5 ? 'ativo' : ''">Orçamento Produto</a>  -->
            </div>
        </div>
        <div class="bloco margem" v-if="aba == 3">
            <ListaComponent :tipo="'projeto'" :idPai="this.idProjeto" @atualizarLista="carregarListadeMateriais" />
        </div>
        <div class="bloco margem container" v-if="aba != 3">
            <PcmComponent v-if="aba == 1" :idPCM="pcm_id" :perfil="perfil" />
            <SprintsView v-if="aba == 2" />
            <OrcamentoProduto v-if="aba == 5"></OrcamentoProduto>
            <div v-if="aba == 4">
                <div class="tags m-b">
                    <a :class="produtoId == i.id ? 'ativo' : ''" v-for="i, index in produtos" :key="index"
                        @click="produtoId = i.id">{{ i.cod }}</a>
                    <a :class="produtoId == null ? 'ativo' : ''" @click="produtoId = null"
                        title="adicionar produto">+</a>
                </div>
                <CadastroProduto v-if="produtoId || cadastrarProduto" :id="produtoId" />
                <div class="bloco margem" v-else>
                    <div class="grid-2">
                        <div>
                            <label>Copiar dados de produto existente</label>
                            <input type="text" placeholder="Código do Produto" style="max-width: 14rem;"
                                @input="onTyping">
                            <a class="produtoBase" v-if="produtoBase?.desc" @click="produtoId = produtoBase.id">Copiar
                                {{ produtoBase.desc }}</a>
                            <span style="margin-left: .5rem;" v-else-if="produtoBase">{{ produtoBase }}</span>
                        </div>
                        <div>
                            <label>Criar novo produto</label>
                            <div style="display: flex;">
                                <div style="margin-inline: .5em;">
                                    <select>
                                        <option hidden>Fámilia </option>
                                    </select>
                                </div>
                                <div style="margin-inline: .5em;">
                                    <select>
                                        <option hidden>Tipo</option>
                                    </select>
                                </div>
                                <div class="alinha-v" style="margin-inline: .5em;">
                                    <i class="bi bi-check-circle" title="Criar Produto"
                                        @click="cadastrarProduto = true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-mask" @click="pdfUrl = null" v-if="pdfUrl">
        <div style="height: 95vh; width: 55rem; background-color: white; border-radius: 5px;">
            <iframe :src="pdfUrl" style="width: 100%; height: 100%;"></iframe>
        </div>
    </div>
</template>
<script>
import { api } from "roboflex-thalamus-request-handler";
import Cookies from 'js-cookie'
import SprintsView from "@/components/Projetos/SprintsView.vue";
import PcmComponent from "@/components/PCM/pcmComponent.vue";
import ListaComponent from "@/components/Projetos/ListaComponent.vue";
import CadastroProduto from "@/components/Produto/CadastroProduto.vue";
import serviceProdutos from "@/services/serviceProdutos"
import OrcamentoProduto from "@/components/Projetos/OrcamentoProduto.vue";
export default {

    components: {
        SprintsView,
        PcmComponent,
        ListaComponent,
        CadastroProduto,
        OrcamentoProduto
    },

    data() {
        return {
            aba: null,
            produtoId: null,
            produtos: [{ cod: 'USI030004', id: 4057 }],
            cadastrarProduto: false,

            produtoBase: null,

            pdfUrl: null,
            nome: null,
            pcm_codigo: null,
            pcm_id: null,
            idProjeto: null,
            perfil: null,
            tipo: '',
            tipo_id: ''
        }
    },
    created() {
        this.aba = localStorage.getItem('ultimaAbaProjeto') ?? 2
        const details = Cookies.get('projectDetails')
        if (details) {
            var projectDetails = JSON.parse(details)

            this.idProjeto = projectDetails.idProjeto
            this.nome = projectDetails.nome
            this.pcm_codigo = projectDetails.pcm_codigo
            this.pcm_id = projectDetails.pcm_id
            this.perfil = projectDetails.perfil


        } else {
            this.$router.push({ name: 'ControleDeProjetos' })
        }
    },
    methods: {
        salvarAba(id) {
            localStorage.setItem('ultimaAbaProjeto', id)
        },
        onTyping(event) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.findProduto(event.target.value)
            }, 100);
        },
        async findProduto(cod) {
            var produtos = await serviceProdutos.getProdutos()
            this.produtoBase = produtos.find(item => item.cod == cod) ?? 'Produto não encontrado'
        },
        async loadPdf() {
            if (this.pcm_id !== 'null') {
                try {
                    const response = await api.get(`pcm/ ${this.pcm_id}/pdf`, {
                        responseType: 'arraybuffer',
                    });

                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    this.pdfUrl = URL.createObjectURL(blob);
                } catch (error) {
                    console.error('Erro ao carregar o PDF', error);
                }
            }
        },
    }
}
</script>
<style scoped>
.abas {
    display: flex;
    height: 2.5rem;
    margin-left: 1.5rem;
    align-items: end;

    a {
        padding: .5em 1em;
        border-radius: 12px 12px 0 0;
        border: 1px solid var(--cor-separador);
        border-bottom: none;
        align-content: center;
    }

    .ativo {
        background-color: var(--cor-primaria);
        color: var(--cor-bg);
        height: 2.8rem;
        transition: all 400ms ease-in-out;
    }
}

i {
    font-size: 20px;
    cursor: pointer;
}

i:hover {
    color: var(--cor-sucesso);
}

.produtoBase {
    border: 1px solid var(--cor-separador);
    padding: .5rem 1rem;
    border-radius: 12px;
    margin-left: .5rem;
}
</style>