import { api } from "roboflex-thalamus-request-handler";

const funções = {
    async getGrupos() {
        try {
            const response = await api.get(`/protocolo/listar?tipo_id=6`)
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar grupo")
            throw error;
        }
    },

    async deletarGrupo(id) {
        try {
            const response = await api.delete(`/protocolo/excluir/${id}`)
            return response.data;
        } catch (error) {
            console.error("Erro ao excluir grupo")
            throw error;
        }
    },

    async criarGrupo(payload) {
        try {
            const response = await api.post(`/protocolo/gravar`, payload)
            return response.data;
        } catch (error) {
            console.error("Erro ao criar grupo")
            throw error;
        }
    },

    async getRotina(id) {
        try {
            const response = await api.get(`grupo-protocolo/buscar/${id}/plano`)
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async adicionarComentario(payload) {
        try {
            const response = await api.post(`protocolo/comentario/gravar`, payload)
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async adicionarTarefa(payload) {
        try {
            const response = await api.post(`protocolo/tarefa/gravar`, payload)
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async atualizarTarefa(idTarefa, payload) {
        try {
            const response = await api.put(`protocolo/tarefa/atualizar/${idTarefa}`, payload)
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async excluirTarefa(id) {
        try {
            const response = await api.post(`/protocolo/tarefa/excluir/${id}`)
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

}

export default funções;