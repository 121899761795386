<template>
    <div class="modal-mask" @click="fecharModal">
        <div class="jm margem" style="min-width: 30vw;" @click.stop>
            <header>
                <h3>{{ `${protocolo.codigo} - ${protocolo.nome}` }}</h3>
            </header>
            <div class="margem" style="max-height: 30vh; overflow: auto;">
                <table class="tabela">
                    <tbody>
                        <tr>
                            <th style="text-align: left;" colspan="2">
                                Lista de Participantes
                            </th>
                        </tr>
                        <tr v-for="i in participantes" :key="i.id">
                            <td style="text-align: left;">
                                {{ i.nome }}
                            </td>
                            <td>
                                <i style="color: red; font-size: 16px; cursor: pointer;" title="Remover"
                                    class="bi bi-trash3"
                                    @click="participantes = participantes.filter(item => item.id != i.id)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="margem">
                <input type="text" v-model="searchQueryUser" @focusin="this.procurar($event.target.value)"
                    @input="this.procurar($event.target.value)" @focusout="fecharLista()"
                    placeholder="Adicionar Usuario">
                <div class="dropdown-list" v-if="listaUsuariosFiltrada">
                    <ul style="list-style: none;">
                        <li style="cursor: pointer"
                            v-for="item in listaUsuariosFiltrada.filter(i => !participantes.map(item => item.id).includes(i.id))"
                            :key="item.id" @click="participantes.push(item)">
                            <div
                                style="display: flex; align-items: center; padding: 5px; border-radius: 10px; margin-right: 3rem;">
                                {{ item.nomeCompleto }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="submit m-b direita">
                <button @click="salvarParticipantes()">
                    Salvar
                </button>
                <button class="acao-secundaria" @click="fecharModal">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { consultarSetores } from '@/services/usuario-setor';

export default {
    name: 'ModalParticipantesProtocolo',
    data() {
        return {
            searchQueryUser: null,
            listaUsuariosFiltrada: null,
            usuarios: null,

            participantes: []
        }
    },
    props: {
        protocolo: {
            Required: true,
        },
    },
    async mounted() {
        this.usuarios = (await consultarSetores()).usuarios.map(i => ({
            ...i,
            nome: i.nomeCompleto
        }));
        this.participantes = this.protocolo.participantes
    },
    methods: {
        salvarParticipantes(){
            this.$emit('salvar', this.participantes.map(i => i.id));
            this.fecharModal
        },
        fecharModal() {
            this.$emit('fecharModal')
        },
        procurar(texto) {
            if (!texto) {
                this.listaUsuariosFiltrada = this.usuarios
            } else {
                if (this.listaUsuariosFiltrada !== null) {
                    this.listaUsuariosFiltrada = this.usuarios
                    this.listaUsuariosFiltrada = this.listaUsuariosFiltrada.filter(nome => nome.nomeCompleto.toLowerCase().includes(texto.toLowerCase()));
                }
            }
        },
        fecharLista() {
            setTimeout(() => {
                this.listaUsuariosFiltrada = null;
                this.searchQueryUser = null;
            }, 200);
        },
    },
}
</script>

<style scoped>
.dropdown-list {
    background-color: var(--cor-bg);
    z-index: 99999;
    max-height: 20rem;
    overflow: auto;
    position: absolute;
    width: 20.5rem;
    border: 1px solid var(--cor-separador);
}
</style>