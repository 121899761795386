<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>Nova solicitação</h2>
                </div>
            </div>
            <div class="margem container">
                <div style="text-align: center;">
                    <h3>Área do Solicitante</h3>
                </div>
                <fieldset class="bloco2 margem">
                    <div class="grid-4">
                        <div class="col-2">
                            <label>{{ tipo === 'PAP' ? 'Assunto' : 'Nome' }} do {{ tipo }}</label>
                            <input @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="nome"
                                v-model="PCM.nome" type="text" />
                        </div>
                        <div>
                            <label>
                                Nível
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                Definição do nível de abrangência de um projeto ou PAP, onde:
                                                <br>
                                                <b>• Estratégico:</b> engloba qualquer alteração ou mudança que envolva
                                                diretamente a
                                                aprovação da diretoria,
                                                geralmente se encaixa em projetos, principalmente aqueles com custo
                                                elevado
                                                e
                                                ações
                                                específicas.<br>
                                                <b>• Operacional:</b> é tudo aquilo que pode ser resolvido pelo próprio
                                                setor,
                                                sem
                                                exigência do acompanhamento direto
                                                dos diretores, sendo aprovado pelo líder da área.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="nivel"
                                v-model="PCM.nivel">
                                <option :hidden="tipo == 'PAP'">Estratégico</option>
                                <option :hidden="tipo == 'Programa'">Operacional</option>
                            </select>
                        </div>

                        <div>
                            <label>
                                Tipo
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                <b>• Programa: </b> criado sob solicitação e aprovação da Diretoria.
                                                <br>
                                                <b>• Projeto: </b> solicitado pelos líderes dos setores, fazendo parte
                                                de um
                                                programa. Possui custo programado.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="tipo"
                                v-model="PCM.tipo">
                                <option>Programa</option>
                                <option>Projeto</option>
                                <option :hidden="nivel == 'Estratégico'">PAP</option>
                            </select>
                        </div>

                        <div>
                            <label>
                                Finalidade
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                <b>• Criação:</b> algo inexistente na empresa, que necessita ser criado
                                                para
                                                seu
                                                início.<br>
                                                <b>• Mudança:</b> alteração em algum processo ou produto já existente.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="finalidade"
                                v-model="PCM.finalidade">
                                <option>Criação</option>
                                <option>Mudança</option>
                            </select>
                        </div>
                        <div>
                            <label>
                                Área
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                <b>• Processo:</b> ações comportamentais, mudança nas metodologias.
                                                Exemplo:
                                                alteração
                                                de fluxo,
                                                implementação de rotina de trabalho, alteração em documentos, etc… <br>
                                                <b>• Produto:</b> entregas concretas e palpáveis. Exemplo: case,
                                                catraca,
                                                conversor,
                                                estrutura física,
                                                etc…
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="area"
                                v-model="PCM.area">
                                <option>Processo</option>
                                <option>Produto</option>
                            </select>
                        </div>
                        <div>
                            <label>
                                Setor aprovador
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                Setor que analisará a viabilidade da demanda e aprovará ou rejeitará.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="setor_id"
                                v-model="PCM.setor_id">
                                <template v-if="nivel !== 'Estratégico'">
                                    <option v-for="item in setores ? setores.filter((item) => item.id !== 7) : setores"
                                        :key="item.id" :value="item.id"
                                        :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                        <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                            &nbsp;&nbsp;
                                        </span>
                                        {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                        {{ item.nome }}
                                    </option>
                                </template>
                                <template v-else>
                                    <option :value="7">
                                        Diretoria
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div>
                            <label>
                                Data de abertura
                            </label>
                            <input :class="{ shake: disabled }" id="dtInicio" v-model="PCM.dtInicio" type="date" />
                        </div>
                    </div>
                    <br>
                    <div>
                        <label>Descrição do Problema
                            <v-menu location="end">
                                <template v-slot:activator="{ props }">
                                    <i v-bind="props" class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100">
                                    <div
                                        style="text-align: left; width: 20rem; padding-inline: 10px; padding-bottom: 10px;">
                                        <span>
                                            Definir o problema a ser resolvido. <br><br> <b>Exemplo:</b> Atraso na
                                            chegada
                                            de materiais.
                                        </span>
                                    </div>
                                </v-card>
                            </v-menu>
                        </label>
                        <textarea @focusout="salvarCamposLocal()" :class="{ shake: disabled }"
                            v-model="PCM.descricao_problema" id="descricao_problema"></textarea>
                    </div>
                    <br>
                    <div>
                        <label> Possível solução
                            <v-menu location="end">
                                <template v-slot:activator="{ props }">
                                    <i v-bind="props" class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100">
                                    <div
                                        style="text-align: left; width: 25rem; padding-inline: 10px; padding-bottom: 10px;">
                                        <span>
                                            Definir a possível solução para o problema descrito. <br><br>
                                            <b>Exemplo:</b>
                                            Criação de
                                            novo
                                            fluxo para que
                                            não haja problema na entrega de materiais, bem como treinamento com
                                            todos os
                                            envolvidos.
                                        </span>
                                    </div>
                                </v-card>
                            </v-menu>
                        </label>
                        <textarea @focusout="salvarCamposLocal()" :class="{ shake: disabled }"
                            v-model="PCM.possivel_solucao" id="possivel_solucao"></textarea>
                    </div>
                    <br>
                    <div>
                        <label>Objetivos Estratégicos</label>
                        <div style="display: flex;">
                            <label>
                                <input type="checkbox"> Assegurar a rentabilidade
                            </label>
                            <label>
                                <input type="checkbox"> Aumentar a receita de vendas Faturamento
                            </label>
                            <label>
                                <input type="checkbox"> Assegurar a satisfação do cliente
                            </label>
                            <label>
                                <input type="checkbox"> Garantir produtividade e qualidade
                            </label>
                            <label>
                                <input type="checkbox"> Aumentar competência e engajamento do time
                            </label>
                        </div>
                    </div>
                    <br>
                    <div>
                        <label>Propósito da mudança
                            <v-menu location="end">
                                <template v-slot:activator="{ props }">
                                    <i v-bind="props" class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100">
                                    <div
                                        style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                        <span>
                                            Definir o motivo da implementação da mudança ou criação. <br><br>
                                            <b>Exemplo:</b> A fim de
                                            evitar o atraso
                                            das entregas e da produção dos produtos, será necessária uma mudança no
                                            processo.
                                        </span>
                                    </div>
                                </v-card>
                            </v-menu>
                        </label>
                        <textarea @focusout="salvarCamposLocal()" :class="{ shake: disabled }"
                            v-model="PCM.proposito_mudanca" id="proposito_mudanca"></textarea>
                    </div>
                    <div style="margin-top: 1rem;">
                        <label>Escopo</label>
                        <textarea v-model="PCM.escopo"></textarea>
                    </div>


                    <div class="grid-4 margem">
                        <!-- <div>
                            <label>Estimativa de custo
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 30rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                Definição do valor total a ser investido na mudança ou criação. Será
                                                apenas uma
                                                estimativa
                                                para comparações futuras.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu></label>
                            <money3 v-model="PCM.estimativa_custo" v-bind="config" @focusout="salvarCamposLocal()"
                                :disabled="this.nivel == 'Operacional'"
                                style=" width: 100%;border: 1px solid var(--cor-separador); height: 3rem; border-radius: 6px; text-align: center;">
                            </money3>
                        </div> -->
                        <div>
                            <label>Data prevista de implementação</label>

                            <input :class="{ shake: disabled }" @focusout="salvarCamposLocal()"
                                id="dtPrevistaImplementacao" v-model="PCM.dtEstimadaFinal" type="date" />
                        </div>
                        <div>
                            <label>Responsável pela solicitação
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 25rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                Colaborador que abre e preenche o documento inicial.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" class="form-select" v-model="PCM.solicitante_id"
                                :class="{ shake: disabled }" id="solicitante_id">
                                <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                    {{ nomeEsobrenome(item.nomeCompleto) }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>
                                Setor demandante
                                <v-menu location="end">
                                    <template v-slot:activator="{ props }">
                                        <i v-bind="props" class="bi bi-info-circle"></i>
                                    </template>

                                    <v-card min-width="100">
                                        <div
                                            style="text-align: left; width: 25rem; padding-inline: 10px; padding-bottom: 10px;">
                                            <span>
                                                Setor em que o responsável pela solicitação se encontra.
                                            </span>
                                        </div>
                                    </v-card>
                                </v-menu>
                            </label>
                            <select @focusout="salvarCamposLocal()" :class="{ shake: disabled }" id="setorDemanda_id"
                                v-model="PCM.setorDemanda_id">
                                <option v-for="item in setores" :key="item.id" :value="item.id"
                                    :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                    <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                        &nbsp;&nbsp;
                                    </span>
                                    {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                    {{ item.nome }}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <br>
                <div>
                    <div style="text-align: center">
                        <h3>Área do Responsável</h3>
                    </div>
                    <fieldset class="bloco2 margem">
                        <table class="tabela" style="border-bottom: 1px solid var(--cor-separador);">
                            <tbody>
                                <tr>
                                    <th scope="col">Análise de impactos e viabilidade da proposta</th>
                                    <th scope="col">Concordo</th>
                                    <th scope="col">Justificativa</th>
                                </tr>
                                <tr v-for="(item, index) in impacto_viabilidade" :key="index">
                                    <td>
                                        <div>
                                            {{ item.descricao }}
                                        </div>
                                    </td>
                                    <td style="text-align: center; padding: 0;">
                                        <div class="tags" style="justify-content: center;">
                                            <a @click="item.concordo = true, atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                                :class="item.concordo == true ? 'ativo' : ''">Sim</a>
                                            <a @click="item.concordo = false, atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                                :class="item.concordo == false ? 'ativo' : ''">Não</a>
                                        </div>
                                    </td>
                                    <td style="padding: 1rem; width: 30%;">
                                        <textarea v-model="item.justificativa" rows="3" :class="{ shake: disabled }"
                                            :id="'justificativa' + index" :title="item.justificativa"
                                            @change="atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>Estimativa de Custo da Mudança</div>
                                    </td>
                                    <td>
                                        <money3 :class="{ shake: disabled }" v-model="PCM.estimativa_custo"
                                            @focusout="atualizarPCM('estimativa_custo', PCM.estimativa_custo)"
                                            v-bind="config"
                                            style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                                        </money3>
                                    </td>
                                    <td style="padding: 1rem">
                                        <textarea :class="{ shake: disabled }" v-model="PCM.custo_justificativa"
                                            rows="3" :title="PCM.custo_justificativa" id="custo_justificativa"
                                            @change="atualizarPCM('custo_justificativa', PCM.custo_justificativa)"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            style="border: 1px solid var(--cor-separador); padding: 5px; width: 100%; border-radius: 10px; margin-top: 0.5rem;">
                            <span>
                                Confirmo que o cenário geral do mercado é viável à proposta, pois
                                apresenta
                                condições favoráveis que podem sustentar o sucesso da iniciativa.
                                Além
                                disso, a
                                empresa possui capacidade interna necessária para implementá-la,
                                contando com
                                recursos humanos qualificados e infraestrutura adequada. Com isso,
                                demonstra-se
                                que
                                a implementação da proposta será rentável à empresa, gerando
                                retornos
                                positivos.
                            </span>
                        </div>
                        <div class="grid-3 margem">
                            <div>
                                <label>Data prevista de implementação</label>
                                <input :class="{ shake: disabled }" v-model="PCM.dtEstimadaFinal" type="date"
                                    id="dtEstimadaFinal"
                                    @change="atualizarPCM('dtEstimadaFinal', PCM.dtEstimadaFinal)" />
                            </div>
                            <div>
                                <label>Data limite de implementação</label>
                                <input :class="{ shake: disabled }" v-model="PCM.dtLimiteImplementacao" type="date"
                                    id="dtLimiteImplementacao"
                                    @change="atualizarPCM('dtLimiteImplementacao', PCM.dtLimiteImplementacao)" />
                            </div>
                            <div>
                                <label>Gerente responsável</label>
                                <select :class="{ shake: disabled }" v-model="PCM.gerentePCM_id" id="gerentePCM_id"
                                    @change="atualizarPCM('gerentePCM_id', PCM.gerentePCM_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>Parecer</label>
                                <select :class="{ shake: disabled }" v-model="PCM.parecer_responsavel"
                                    id="parecer_responsavel"
                                    @change="atualizarPCM('parecer_responsavel', PCM.parecer_responsavel)"
                                    :style="{ color: PCM.parecer_responsavel == 'Proposta viável' ? 'var(--cor-sucesso)' : 'var(--cor-erro)' }">
                                    <option style="color: var(--cor-sucesso)">Proposta viável</option>
                                    <option style="color: var(--cor-erro)">Proposta inviável</option>
                                </select>
                            </div>
                            <div>
                                <label> Nome do Aprovador </label>
                                <select :class="{ shake: disabled }" v-model="PCM.responsavel_id" id="responsavel_id"
                                    @change="atualizarPCM('responsavel_id', PCM.responsavel_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>Justificativa / Observação</label>
                            <textarea :class="{ shake: disabled }" v-model="PCM.responsavel_justificativa"
                                id="responsavel_justificativa" :title="PCM.responsavel_justificativa"
                                @focusout=" atualizarPCM('responsavel_justificativa', PCM.responsavel_justificativa)"></textarea>
                        </div>
                        <br>
                        <div>
                            <label>Resultado esperado</label>
                            <textarea :class="{ shake: disabled }" v-model="PCM.resultado_esperado"
                                id="resultado_esperado" :title="PCM.resultado_esperado"
                                @focusout=" atualizarPCM('resultado_esperado', PCM.resultado_esperado)"></textarea>
                        </div>

                        <div class="grid-3 margem" style="padding-bottom: 0;" v-if="PCM.tipo !== 'Projeto'">
                            <div>
                                <label>Necessidade de Cadastro no Omie?</label>
                                <div class="tags m-b">
                                    <a @click="PCM.cadastro_omie = 1, atualizarPCM('cadastro_omie', 1)"
                                        :class="PCM.cadastro_omie == 1 ? 'ativo' : ''">Sim</a>
                                    <a @click="PCM.cadastro_omie = 0, atualizarPCM('cadastro_omie', 0)"
                                        :class="PCM.cadastro_omie == 0 ? 'ativo' : ''">Não</a>
                                </div>
                            </div>
                            <div>
                                <label>Responsável pelo cadastro no sistema</label>
                                <select v-model="PCM.responsavelCadastro_id"
                                    @change="atualizarPCM('responsavelCadastro_id', PCM.responsavelCadastro_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label> Data </label>
                                <input type="date" v-model="PCM.dtCadastro"
                                    @change="atualizarPCM('dtCadastro', PCM.dtCadastro)" />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="alinha-direita submit">
                    <button @click="novoPCM()">Salvar</button>
                    <button class="acao-secundaria" @click="voltarHome()">Cancelar</button>
                    <b :class="{ shake: disabled }"
                        style="margin-top: 0.5rem; margin-right: 1rem; display: none; color: var(--cor-erro);"
                        id="aviso">Preencha os
                        campos destacados!</b>
                </div>
            </div>


            <!-- modal confirmação de criação -->
            <div style="overflow: auto" class="modal-mask" v-if="modalConfirmacao" @click="fecharModalFora">
                <div style="height: max-content; width: fit-content; padding: 1rem 2rem; border-radius: 5px;"
                    class="jm">
                    <div style="justify-content: center;" class="alinha-centro">
                        <h3>{{ this.tipo }} submetido para aprovação!</h3>
                        <button @click="voltarHome()">Confirmar</button>
                    </div>
                </div>
            </div>
            <!-- end modal -->
            <br /><br /><br />
        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { Money3Component } from "v-money3";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor'
import serviceFunções from "@/services/serviceFunções";

export default {
    name: "VisualizaçãoCriaçãoPCM",
    components: { money3: Money3Component },

    setup() {
        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };

        return {
            nomeEsobrenome
        };
    },

    data() {
        return {
            modalConfirmacao: false,

            PCM: {},

            setores: '',
            usuarios: null,

            impacto_viabilidade: [
                {
                    "impactoViabilidade_id": 1,
                    "concordo": null,
                    "justificativa": "",
                    "descricao": 'Afetará a documentação de produtos já existentes? (B.O.M, Roteiro de Produção, Esquema elétrico, Estrutura, etc.)'
                },
                {
                    "impactoViabilidade_id": 6,
                    "concordo": null,
                    "justificativa": "",
                    "descricao": 'O produto a ser alterado/criado possui ou deve possuir certificação? Se positivo, comunicar mudança à certificadora e aguardar a autorização da mesma.'
                },
            ],


            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },
        };
    },

    created() {
        this.getSetores(),
            this.definirCampos(),
            this.tipo = localStorage.getItem('Tipo')
        // this.nivel = localStorage.getItem('Nivel')
        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id // id
    },

    methods: {
        definirCampos() {
            const itensPreSalvos = JSON.parse(localStorage.getItem("itensPreSalvos"));
            if (itensPreSalvos) {
                itensPreSalvos.forEach(objeto => {
                    for (const chave in objeto) {
                        this[chave] = objeto[chave];
                    }
                });
            }
        },

        salvarCamposLocal() {
            var itensPreSalvos = [
                { nome: this.nome },
                { tipo: this.tipo },
                { nivel: this.nivel },
                { finalidade: this.finalidade },
                { area: this.area },
                { setor_id: this.setor_id },
                { descricao_problema: this.descricao_problema },
                { possivel_solucao: this.possivel_solucao },
                { proposito_mudanca: this.proposito_mudanca },
                { estimativa_custo: this.estimativa_custo },
                { dtLimiteImplementacao: this.dtLimiteImplementacao },
                { solicitante_id: this.solicitante_id },
                { setorDemanda_id: this.setorDemanda_id },
                { dtEstimadaFinal: this.dtEstimadaFinal }
            ];

            localStorage.setItem('itensPreSalvos', JSON.stringify(itensPreSalvos))
            localStorage.setItem('Nivel', this.nivel)
        },

        voltarHome() {
            this.$router.push({ name: "ControlePCMDemandados" });
            localStorage.removeItem('itensPreSalvos')
        },

        validarCampos() {
            var camposVazios = []
            if (!this.PCM.dtInicio) camposVazios.push('dtInicio');
            if (!this.PCM.dtEstimadaFinal) camposVazios.push('dtPrevistaImplementacao');
            if (!this.PCM.finalidade) camposVazios.push('finalidade');
            if (!this.PCM.area) camposVazios.push('area');
            if (!this.PCM.setor_id) camposVazios.push('setor_id');
            if (!this.PCM.nome) camposVazios.push('nome');
            if (!this.PCM.tipo) camposVazios.push('tipo');
            if (!this.PCM.nivel) camposVazios.push('nivel');
            if (!this.PCM.descricao_problema) camposVazios.push('descricao_problema');
            if (!this.PCM.possivel_solucao) camposVazios.push('possivel_solucao');
            if (!this.PCM.proposito_mudanca) camposVazios.push('proposito_mudanca');
            if (!this.PCM.solicitante_id) camposVazios.push('solicitante_id');
            if (!this.PCM.setorDemanda_id) camposVazios.push('setorDemanda_id');

            return camposVazios
        },

        novoPCM() {
            var campoVazio = this.validarCampos()
            if (campoVazio.length !== 0) {
                campoVazio.forEach(item => {
                    document.getElementById(item).style.border = 'solid 1px var(--cor-erro)';
                    document.getElementById('aviso').style.display = ''
                    this.disabled = true
                    setTimeout(() => {
                        this.disabled = false
                    }, 1500)
                    return
                });
            } else {
                this.PCM.impacto_viabilidade = this.impacto_viabilidade
                this.PCM.status = this.PCM.nivel == "Estratégico" && this.PCM.tipo !== 'Programa' ? "Aprovação Técnica" : "Aguardando Aprovação";
                this.PCM.usuario_id = this.idUsuario
                api.post(`pcm/cadastrar`, this.PCM)
                    .then(() => {
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                this.modalConfirmacao = true
            }
            localStorage.removeItem('itensPreSalvos')
        },

        objetoSemIndiceDescricao(item) {
            if (item) {
                const { indice, descricao, ...resto } = item;
                indice
                descricao
                return [resto];
            }
        },

        atualizarPCM(itemEditado, valor) {
            api.put(`pcm/atualizar/${this.idPCM}`, {
                [itemEditado]: valor,
            })
                .then(() => { })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getSetores() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.usuarios = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        verPCMs() {
            this.$router.push({ name: "ControlePCM" });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalConfirmacao = false;
            }
        },
    },
};
</script>