import { api } from "roboflex-thalamus-request-handler"; 
import { sso } from "roboflex-thalamus-sso-lib";

export async function getDados() {
    try {
        const dadosUsuario = await api.get(`usuario/${sso.getUsuarioLogado().id}/funcionalidades`);

        return dadosUsuario.data;
    } catch (error) {
        console.error(error);
        throw error; 
    }
}