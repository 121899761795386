<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ nome }}<span @click="loadPdf"
                            :style="{ 'cursor': (this.pcm_codigo !== 'null') ? 'pointer' : 'default' }">{{
                                pcm_codigo == null ? '' : ` - ${pcm_codigo}` }} <i v-if="pcm_codigo !== null"
                                class="bi bi-file-earmark-pdf"></i></span></h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <div v-for="(item) in sprints " :key="item" class="divPaiTabela">
                        <div class="divFundoTabela" v-if="Array.isArray(sprints)">
                            <div class="row">
                                <div style="width: 20%;">
                                    <div style="width: 10rem;">
                                        <h5>
                                            <span style="display: flex;">
                                                <span class="hPoints bg-alerta">{{ somarHP(item)[0] }}</span>
                                                <span class="hPoints bg-ok">{{ somarHP(item)[1] }}</span>
                                                <span class="hPoints bg-sucesso">{{ somarHP(item)[2] }}</span>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <table :id="item.nome" class="tabela">
                                <tr>
                                    <th>
                                        <strong>
                                            Código
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            Descrição
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            H.P.
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            Responsável
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            Inicio Previsto
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            Fim Previsto
                                        </strong>
                                    </th>
                                    <th style="padding: 0rem;">
                                        <strong>
                                            Status
                                        </strong>
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                                <tr v-for="element in item.tarefas" :key="element.id"
                                    @mouseover="mostrarBotao(element.id, true)"
                                    @mouseleave="mostrarBotao(element.id, false)">
                                    <td>
                                        <span style="width: 100%; white-space: nowrap;">{{
                                            element.codigo }}</span>
                                    </td>
                                    <td class="clicavel" style="text-align: left;">
                                        <div @click="abrirModalEditarBacklog(element.id, item.id, false)"
                                            :title="element.descricao"
                                            style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis">
                                            {{ element.descricao }}
                                        </div>
                                    </td>
                                    <td class="clicavel">
                                        <select v-model="element.HP" class="HP" :disabled="perfil != 1"
                                            @focusout="editarBacklog('HP', element.id, element.HP)"
                                            style="opacity: 1;width: 2.5rem; height: 2.5rem;text-align: center; border-radius: 50%; padding: 0;">
                                            <option hidden>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>5</option>
                                            <option>8</option>
                                            <option>13</option>
                                            <option>21</option>
                                            <option>34</option>
                                            <option>55</option>
                                            <option>89</option>
                                            <option>144</option>
                                            <option>233</option>
                                        </select>
                                    </td>
                                    <td class="clicavel">
                                        <select v-model="element.responsavel_id" :disabled="perfil != 1"
                                            style="opacity: 1;background-color: transparent;min-width: max-content; border: none;"
                                            @change="editarBacklog('responsavel_id', element.id, element.responsavel_id)">
                                            <option hidden>Responsável</option>
                                            <option v-for=" item in gerente " :key="item.id" :value="item.id">
                                                {{ nomeEsobrenome(item.nomeCompleto) }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="clicavel">
                                        <input :disabled="perfil != 1"
                                            style="opacity: 1;cursor: pointer; width: 7rem; text-align: left; padding-inline: 0rem; border: none; background-color: transparent;"
                                            type="date"
                                            @change="editarBacklog('dtInicio', element.id, (element.dtInicio + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))"
                                            v-model="element.dtInicio">
                                    </td>
                                    <td class="clicavel">
                                        <input :disabled="perfil != 1"
                                            style="opacity: 1;cursor: pointer; width: 7rem; text-align: left; padding: 0rem; border: none; background-color: transparent;"
                                            type="date" :min="element.dtInicio"
                                            @change="editarBacklog('dtFim', element.id, (element.dtFim + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))"
                                            v-model="element.dtFim">
                                    </td>
                                    <td style="max-width: 14rem; min-width: 10.4rem ;padding: 0rem;">
                                        <div style="position: relative;">
                                            <v-progress-linear
                                                :color="(element.status == 'Cancelada') ? 'red' : (element.status == 'Concluído') ? 'var(--cor-sucesso)' : (element.status == 'Pendente') ? 'var(--cor-alerta)' : (element.status == 'Em andamento' || 'Em andamento 25%' || 'Em andamento 50%' || 'Em andamento 75%') ? 'var(--cor-ok)' : 'red'"
                                                :model-value="element.status == 'Concluído' || element.status == 'Cancelada' ? 100 : element.status == 'Pendente' ? 100 : element.status.split(' ')[2] ? element.status.split(' ')[2].slice(0, 2) : 2"
                                                style="border: 1px solid var(--cor-fonte-fraca); border-radius: 25px; height: 2rem;">
                                            </v-progress-linear>
                                            <select :title="element.status"
                                                :disabled="perfil != 1 && parseInt(element.responsavel_id) !== parseInt(idUsuario)"
                                                :style="{ 'color': (element.status == 'Concluído') ? 'var(--cor-bg)' : 'black' }"
                                                style="opacity: 1;border: none; padding-left: 0.5rem;position: absolute; top: 0; left: 0rem; outline: none; border-radius: 25px ;height: 2rem ;text-align: center; background-color: transparent; padding-top: 0rem; padding-bottom: 0rem;"
                                                @change="editarBacklog('status', element.id, element.status)"
                                                v-model="element.status">
                                                <option value="" hidden></option>
                                                <option style="color: rgb(255, 145, 0);" value="Pendente">A iniciar</option>
                                                <option style="color: rgb(0, 47, 255);" :value="'Em andamento'">Em
                                                    andamento
                                                    0%</option>
                                                <option style="color: rgb(0, 47, 255);">Em andamento 25%</option>
                                                <option style="color: rgb(0, 47, 255);">Em andamento 50%</option>
                                                <option style="color: rgb(0, 47, 255);">Em andamento 75%</option>
                                                <option style="color: rgb(0, 192, 0);">Concluído</option>
                                                <option style="color: red;">Cancelada</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <v-menu>
                                            <template v-slot:activator="{ props }">
                                                <v-btn :id="'botaoEdicao' + element.id"
                                                    style="visibility: hidden; width: 2rem; height: 2rem; background-color: transparent; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                    class="botaoAdicionarSprint acao-secundaria"
                                                    icon="mdi-dots-horizontal" v-bind="props"></v-btn>
                                            </template>

                                            <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                <v-list-item style="margin: 0.2rem;"
                                                    @click="abrirModalEditarBacklog(element.id, item.id, false)">
                                                    Editar Tarefa
                                                </v-list-item>
                                                <v-list-item :disabled="perfil != 1">
                                                    <v-menu>
                                                        <template v-slot:activator="{ props }">
                                                            <span
                                                                style="color: var(--cor-erro);margin: 0.2rem; cursor: pointer;"
                                                                v-bind="props">Excluir
                                                                Tarefa</span>
                                                        </template>
                                                        <v-list
                                                            style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                            <v-list-item>
                                                                <div>
                                                                    <h3>Tem certeza? Esta ação é Irreversível!</h3>
                                                                    <div class="submit">
                                                                        <Button
                                                                            @click="apagarBacklog(element.id)">Excluir</Button>
                                                                        <button
                                                                            class="acao-secundaria">Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </table>
                            <div style="display: flex; padding-left: 0.2rem; border-radius: 5px; width: 100%;"
                                v-if="perfil == 1" :id="item.id">
                                <div
                                    style="border: 1px solid var(--cor-separador); border-radius: 5px; padding: 0.3rem; width: 6rem; align-content: center ;text-align: center;">
                                    <strong>
                                        {{ this.somenteBacklogs().length !== 0 ? 'Tarefa - ' +
                                            (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1) :
                                            'Tarefa - 1' }}
                                    </strong>
                                </div>
                                <div
                                    style="border: 1px solid var(--cor-separador); border-radius: 5px; width: 100%; margin-left: 0.3rem; padding: 0.3rem;">
                                    <input type="text" placeholder="O que será feito?"
                                        @keyup.enter="criarBacklog(item.id, $event.target.value), $event.target.value = ''"
                                        style="width: 100%; padding: 0.1rem; padding-left: 0.5rem; outline: none; height: 2rem; border: none;"
                                        :id="'inputNovaTarefa' + item.id">
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center; display: none;" :id="'pontos' + item.nome"
                            @click="ocultarPlano(item.nome)" class="ocultar">
                            <i class="bi bi-grip-horizontal"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-mask" @click="pdfUrl = null" v-if="pdfUrl">
                    <div style="height: 95vh; width: 55rem; background-color: white; border-radius: 5px;">

                        <iframe :src="pdfUrl" style="width: 100%; height: 100%;"></iframe>

                    </div>
                </div>

                <!-- MODAL EDITAR BACKLOG-->
                <div class="modal-mask" v-if="showEditarBacklog" @click="fecharModalFora">
                    <div class="jm" style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;">
                        <div>
                            <h3>Editar: {{ backlogeditado.codigo }} </h3>
                        </div>
                        <hr>
                        <br>
                        <div class="grid-2">
                            <div>
                                <label>Descrição</label>
                                <textarea v-model="backlogeditado.descricao" :disabled="perfil != 1"
                                    @focusout="editarBacklog('descricao', backlogeditado.id, backlogeditado.descricao)"></textarea>
                            </div>

                            <div>
                                <label>Responsável</label>
                                <select v-model="backlogeditado.responsavel_id" :disabled="perfil != 1"
                                    @change="editarBacklog('responsavel_id', backlogeditado.id, backlogeditado.responsavel_id)">
                                    <option v-for=" item in gerente " :key="item.id" :value="item.id">
                                        {{ item.nomeCompleto }}
                                    </option>
                                </select>
                            </div>

                            <div class="grid-2">
                                <div>
                                    <label>Inicio Previsto</label>
                                    <input v-model="backlogeditado.dtInicio" type="date" :disabled="perfil != 1"
                                        @change="editarBacklog('dtInicio', backlogeditado.id, (backlogeditado.dtInicio + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Fim Previsto</label>
                                    <input v-model="backlogeditado.dtFim" type="date" :disabled="perfil != 1"
                                        @change="editarBacklog('dtFim', backlogeditado.id, (backlogeditado.dtFim + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Inicio Real</label>
                                    <input v-model="backlogeditado.dtInicioReal" type="date" :disabled="perfil != 1"
                                        @change="editarBacklog('dtInicioReal', backlogeditado.id, (backlogeditado.dtInicioReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Fim Real</label>
                                    <input v-model="backlogeditado.dtFimReal" type="date" :disabled="perfil != 1"
                                        @change="editarBacklog('dtFimReal', backlogeditado.id, (backlogeditado.dtFimReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                            </div>

                            <div>
                                <label>Observações</label>
                                <textarea v-model="backlogeditado.obs" rows="6"
                                    @focusout="editarBacklog('obs', backlogeditado.id, backlogeditado.obs)"></textarea>
                            </div>
                            <div class="col-2" style="margin-bottom: 0;">
                                <span>
                                    Anexos:
                                </span>
                                <input style="display: none;" ref="fileInput" class="form-control form-control-sm"
                                    type="file" @change="handleFileUpload">

                                <ul style="list-style: none; margin-bottom: 0;" class="grid-3">
                                    <li v-for="item in backlogeditado.anexos" :key="item"
                                        @mouseover="mostrarBotaoExcluirAnexo(item.id, true)"
                                        @mouseleave="mostrarBotaoExcluirAnexo(item.id, false)">
                                        <div class="anexo">
                                            <a :href="urlFoto.caminhoFoto + item.path" target="_blank">
                                                {{ item.nome }}
                                            </a>
                                            <i @click="excluirAnexo(item.id)" :id="'botaoExcluir' + item.id"
                                                style="color: red; visibility: hidden;"
                                                class="bi bi-trash-fill botaoAdicionarSprint"></i>
                                        </div>
                                    </li>
                                    <div @click="openFileInput" style="cursor: pointer" class="anexo"
                                        v-if="perfil == 1">
                                        Adicionar <i title="Adicionar anexo" style="justify-self: right;"
                                            class="bi bi-plus-circle"></i>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END MODAL -->
            </div>
        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { ref } from 'vue';
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import { urlFoto } from "../../services/api";
import serviceFunções from "@/services/serviceFunções";
import Cookies from 'js-cookie'

export default {
    name: "VisualizaçãoPlanodeAção",

    setup() {
        const fileInput = ref(null);

        const openFileInput = () => {
            fileInput.value.click();
        };
        const somarHP = (dados) => {
            return serviceFunções.somarHP(dados);
        };
        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };
        return { fileInput, openFileInput, urlFoto, somarHP, nomeEsobrenome };
    },

    data() {
        return {
            pcm_codigo: null,
            pcm_id: null,
            idProjeto: null,
            nome: null,
            perfil: null,

            pdfUrl: null,
            idUsuario: null,
            backlogs: [],
            showEditarSprint: false,
            showEditarBacklog: false,
            showIniciarSprint: false,
            showConfirmação: false,
            sprints: null,
            idSprint: null,
            backlogeditado: null,
            gerente: [],
            sprintEditada: null
        }
    },

    watch: {
        sprints: {
            handler: 'moverBacklog',
            deep: true,
        },
    },

    mounted() {
        this.getBacklogs(),
            this.getGerenteseSetor()
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id

        const details = Cookies.get('projectDetails')
        if (details) {
            var projectDetails = JSON.parse(details)
            this.idProjeto = projectDetails.idProjeto
            this.nome = projectDetails.nome
            this.pcm_codigo = projectDetails.pcm_codigo
            this.pcm_id = projectDetails.pcm_id
            this.perfil = projectDetails.perfil
        } else {
            this.$router.push({ name: 'ControleDeProjetos' })
        }
    },

    methods: {
        async loadPdf() {
            if (this.pcm_id !== 'null') {
                try {
                    const response = await api.get(`pcm/${this.pcm_id}/pdf`, {
                        responseType: 'arraybuffer', // Isso é importante para receber o PDF como dados binários
                    });

                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    this.pdfUrl = URL.createObjectURL(blob);
                } catch (error) {
                    console.error('Erro ao carregar o PDF', error);
                }
            }
        },

        handleFileUpload() {
            const fileInput = this.$refs.fileInput;

            if (fileInput && fileInput.files.length > 0) {
                const formData = new FormData();
                formData.append('planoAcaoTarefa_id', this.backlogeditado.id);

                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('anexos[]', fileInput.files[i]);
                }
                api.post('planoAcaoTarefa/anexo/adicionar', formData)
                    .then(response => {
                        this.backlogeditado.anexos.push({
                            path: response.data.anexos_salvos[0].path,
                            nome: response.data.anexos_salvos[0].nome,
                            id: response.data.anexos_salvos[0].id
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },

        excluirAnexo(id) {
            api.delete(`sprintTarefa/anexo/remover/${id}`);
            this.backlogeditado.anexos = this.backlogeditado.anexos.filter(anexo => anexo.id !== id)
        },

        mostrarBotaoExcluirAnexo(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoExcluir' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoExcluir' + id).style.visibility = 'hidden'
            }
        },

        somenteBacklogs() {
            if (this.sprints !== null) {
                var sprints = this.sprints.map((item) => item.tarefas).flat()
                sprints.sort((a, b) => b.id - a.id);
                return sprints
            }
            return 'nada'
        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        getBacklogs() {
            api.get(`planoAcao/buscar/${this.idProjeto}`)
                .then((response) => {
                    this.sprints = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        definirInicioFimReal(idBacklog, status) {
            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia

            if (status == 'Em andamento') {
                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: data
                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } if (status == 'Concluído') {
                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtFimReal: data
                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } if (status == 'Pendente') {
                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: null,
                    dtFimReal: null
                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } else {
                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtFimReal: null,
                })
                    .then(() => {
                        return this.getBacklogs()
                    })
            }
        },

        abrirModalEditarBacklog(idBacklog) {
            this.showEditarBacklog = true;
            let sprint = this.sprints[0]
            this.backlogeditado = sprint.tarefas.find(tarefa => tarefa.id === idBacklog);
        },

        editarBacklog(itemAlterado, idBacklog, novoValor) {
            api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                usuario_id: this.idUsuario,
                [itemAlterado]: novoValor
            })
                .then(() => {
                    if (itemAlterado == 'status') {
                        return this.definirInicioFimReal(idBacklog, novoValor)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.showEditarBacklog = false;
                this.showIniciarSprint = false;
                this.showConfirmação = false;
                this.showEditarSprint = false
            }

        },

        fecharModal() {
            this.showIniciarSprint = false;
        },

        criarBacklog(id, descricao) {
            if (this.somenteBacklogs().length !== 0) {
                api.post(`planoAcaoTarefa/cadastrar`, {
                    planoAcao_id: id,
                    codigo: 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                    descricao: descricao
                })
                    .then((response) => {
                        localStorage.setItem('ultimaSprintEditada', id)
                        var novoItem = {
                            "id": response.data.id,
                            "sprint_id": id,
                            "codigo": 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                            "descricao": descricao,
                            "HP": 0,
                            "responsavel_id": 0,
                            "status": "Pendente",
                            "dtInicio": null,
                            "dtFim": null,
                            "dtInicioReal": null,
                            "dtFimReal": null,
                            "responsavel": null,
                            "anexos": []
                        };
                        const sprint = this.sprints.find(item => item.id === id)
                        sprint.tarefas.push(novoItem)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                api.post(`planoAcaoTarefa/cadastrar`, {
                    planoAcao_id: id,
                    codigo: 'Tarefa - 1',
                    descricao: descricao
                })
                    .then(() => {
                        sessionStorage.setItem('ultimaSprintEditada', id)
                        this.getBacklogs();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        apagarBacklog(idBacklog) {
            api.put(`planoAcaoTarefa/excluir/${idBacklog}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
}
</script>

<style scoped></style>