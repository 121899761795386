import { api } from "roboflex-thalamus-request-handler";

const funções = {
    async solicitarEncerramento(payload) {
        try {
            const response = await api.post(`protocolo/encerrar`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
}
export default funções;