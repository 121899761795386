<template>
    <div class="titulo">
        <div class="margem container">
            <div class="m-icone direita">
                <div class="pesquisa">
                    <input type="text" placeholder="Pesquisar PCM" v-model="PCMSelecionado" @input="filtrarPCMs()" />
                    <a class="icone-pesquisa" title="Pesquise"></a>
                </div>
            </div>
            <h2>Propostas de Criação ou Mudança</h2>
        </div>
    </div>
    <div class="margem container">
        <div class="bloco margem">
            <div class="tags">
                <a v-if="dadosUsuario?.funcionalidade_id.includes(111)"
                    @click="mostrarOperacionais = !mostrarOperacionais, this.getPCMs()"
                    :class="mostrarOperacionais == true ? 'ativo' : ''" style="cursor: pointer;">Mostrar
                    operacionais</a>

                <a @click="mostrarAprovados = !mostrarAprovados, this.getPCMs()"
                    :class="mostrarAprovados == true ? 'ativo' : ''" style="cursor: pointer;">Mostrar aprovador
                    e reprovados</a>
            </div>
            <table class="tabela">
                <tbody>
                    <tr>
                        <th>
                        </th>
                        <th scope="col">
                            Tipo
                        </th>
                        <th scope="col">
                            Código
                        </th>
                        <th scope="col">
                            Nome
                        </th>
                        <th scope="col">
                            Setor aprovador
                        </th>
                        <th scope="col">
                            Data Início
                        </th>
                        <th scope="col">
                            Status
                        </th>
                    </tr>
                    <tr @click="item.tipo == 'Encerramento' ? abrirModal(item) : verPCM(item.id)"
                        v-for="item in listaPCMsFiltrada" :key="item.id">
                        <td>
                            <i v-if="item.nivel == 'Estratégico'" style="font-size: 19px; color: var(--cor-fonte)"
                                class="fa-solid fa-chess-rook"></i>
                            <i v-if="item.nivel == 'Operacional'" style="font-size: 19px; color: var(--cor-fonte)"
                                class="fa-solid fa-screwdriver-wrench"></i>
                            <i v-if="item.tipo == 'Encerramento'" style="font-size: 20px; color: var(--cor-fonte)"
                                class="bi bi-flag-fill"></i>
                        </td>
                        <td style="white-space: nowrap;">
                            {{ item.tipo }}
                        </td>
                        <td>
                            {{ item.codigo ?? '-' }}
                        </td>
                        <td>
                            <span :title="item.nome" style="width: 100% !important;">
                                {{ item.nome ?? item.projeto_nome }}
                            </span>
                        </td>
                        <td>
                            {{ item.setorAprovacao_nome ?? item.setor_nome ?? '-' }}
                        </td>
                        <td>
                            {{ item.dtInicio ?
                                `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                : '-' }}
                        </td>
                        <td
                            :style="{ 'color': (item.status == 'Aguardando Aprovação' || item.status == 'Aprovação Estratégica' || item.status == 'Aprovação Técnica') || item.status == 'Aguardando aprovação' ? 'rgb(255, 145, 0)' : (item.status == 'Reprovado') ? '#e02130' : (item.status == 'Aprovado') ? 'rgb(0, 192, 0)' : 'red', }">
                            {{ item.status ?? 'Aguardando Aprovação' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- MODAL ENCERRAR PROJETO -->
    <div style="overflow: auto" class="modal-mask" v-if="modalEncerrarProjeto" @click="modalEncerrarProjeto = false">
        <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;" @click.stop>
            <div style="display: flex; justify-content: center">
                <h2>Encerrar: {{ propostaEncerramento.projeto_nome }}</h2>
            </div>
            <br>
            <fieldset class="margem">
                <div class="grid-2">
                    <div>
                        <label>Data de termino do projeto</label>
                        <input v-model="propostaEncerramento.dtConclusao" type="date">
                    </div>
                    <div class="grid-2">
                        <div>
                            <label>Custo previsto</label>
                            <money3 v-bind="config" v-model="propostaEncerramento.custoEstimado"
                                style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                            </money3>
                        </div>
                        <div>
                            <label>Custo Real</label>
                            <money3 v-bind="config" v-model="propostaEncerramento.custoReal"
                                style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                            </money3>
                        </div>
                    </div>
                    <div>
                        <label>
                            Evidencias de alcance
                        </label>
                        <textarea v-model="propostaEncerramento.evidenciaAlcance"></textarea>
                    </div>
                    <div>
                        <label>Escopo alcançado</label>
                        <textarea v-model="propostaEncerramento.escopoAlcancado"></textarea>
                    </div>
                </div>
            </fieldset>
            <div style="align-content: end; margin-left: 1rem;">
                <button :disabled="propostaEncerramento.status !== 'Aguardando aprovação'"
                    @click="aprovarEncerramento(propostaEncerramento), modalEncerrarProjeto = false"
                    class="aprovar acao-secundaria bg-sucesso">Aprovar</button>
                <button :disabled="propostaEncerramento.status !== 'Aguardando aprovação'"
                    @click="reprovarEncerramento(propostaEncerramento), modalEncerrarProjeto = false"
                    style="margin-left: 5px;" class="reprovar acao-secundaria bg-erro">Reprovar</button>
            </div>
        </div>
    </div>
    <!-- END MODAL -->

</template>
<script>

import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";
import { consultarSetores } from '@/services/usuario-setor';
import { Money3Component } from "v-money3";
import serviceFunções from "@/services/serviceFunções";
import { getDados } from '@/services/serviceFuncionalidades'

export default {
    name: "ControlePCM",

    components: { money3: Money3Component },

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        return {
            formatarDataHora
        };
    },

    data() {
        return {
            modalEncerrarProjeto: false,
            propostaEncerramento: null,
            SolicitaçãoEncerramento: [],

            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },

            teste: null,

            PCMSelecionado: null,
            listaPCMsFiltrada: null,

            mostrarOperacionais: false,
            mostrarAprovados: false,

            PCMs: null,
            idUsuario: null,

            dadosUsuario: null,

            usuarios: null,
            setores: null,

        }
    },

    async created() {
        this.dadosUsuario = await getDados()
        this.idUsuario = sso.getUsuarioLogado().id
        this.getSetores();
        this.getPCMs()
    },

    methods: {
        abrirModal(item) {
            this.propostaEncerramento = item
            this.modalEncerrarProjeto = true
        },

        aprovarEncerramento(propostaEncerramento) {
            if (propostaEncerramento.projeto_id) {
                api.put(`projeto/encerrar/${propostaEncerramento.projeto_id}`, {
                    status: 'Aprovado'
                })
                this.getPCMs()
            }
            else if (propostaEncerramento.protocolo_id) {
                api.put(`protocolo/encerrar/${propostaEncerramento.protocolo_id}`, {
                    status: 'Aprovado'
                })
                this.getPCMs()
            }
        },

        reprovarEncerramento(propostaEncerramento) {
            if (propostaEncerramento.projeto_id) {
                api.put(`projeto/encerrar/${propostaEncerramento.projeto_id}`, {
                    status: 'Reprovado'
                })
                this.getPCMs()
            }
            else if (propostaEncerramento.protocolo_id) {
                api.put(`protocolo/encerrar/${propostaEncerramento.protocolo_id}`, {
                    status: 'Reprovado'
                })
                this.getPCMs()
            }
        },

        apagarPCM(item) {
            api.put(`pcm/excluir/${item.id}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getPCMs()
                })
        },

        filtrarPCMs() {
            if (!this.PCMSelecionado) {
                this.listaPCMsFiltrada = this.PCMs;
            } else {
                const textoLowerCase = this.PCMSelecionado.toLowerCase();
                this.listaPCMsFiltrada = this.PCMs.filter(pcm => {
                    return Object.values(pcm).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
            this.listaPCMsFiltrada = this.listaPCMsFiltrada.concat(this.SolicitaçãoEncerramento)
        },

        async getPedidosEncerramento() {
            var pedidosEncerramentoProjetos = await api.get('projeto/encerrados/listar')
            pedidosEncerramentoProjetos = pedidosEncerramentoProjetos.data.data
            var pedidosEncerramentoProtocolos = await api.get('protocolo/encerrados/listar')
            pedidosEncerramentoProtocolos = pedidosEncerramentoProtocolos.data.data

            var solicitações = pedidosEncerramentoProjetos.concat(pedidosEncerramentoProtocolos).map(item => ({
                ...item,
                tipo: 'Encerramento',
                dtConclusao: item.dtConclusao.slice(0, 10) ?? ''
            }));
            return solicitações.filter(item => this.dadosUsuario?.setor_responsavel.includes(item.setor_id))
        },

        async getPCMs() {
            this.SolicitaçãoEncerramento = await this.getPedidosEncerramento()
            api.get(`pcm/listar`)
                .then((response) => {
                    this.PCMs = response.data.sort((a, b) => {
                        const statusComparison = a.status.localeCompare(b.status);
                        if (statusComparison !== 0) {
                            return statusComparison;
                        } else {
                            const dateA = new Date(a.dtInicio).getTime();
                            const dateB = new Date(b.dtInicio).getTime();
                            return dateB - dateA;
                        }
                    })


                    if (this.dadosUsuario?.funcionalidade_id?.includes(111)) {
                        null
                    } else if (this.dadosUsuario?.funcionalidade_id?.includes(56)) {
                        this.PCMs = this.PCMs.filter(item =>
                            this.dadosUsuario.setor_responsavel.includes(item.setor_id) ||
                            (this.dadosUsuario.setor_responsavel.includes(item.setorDemanda_id) && item.status == 'Aprovação Técnica')
                        );
                        this.mostrarOperacionais = true;
                        this.SolicitaçãoEncerramento = this.SolicitaçãoEncerramento.filter(item => item.nivel !== 'Estratégico');
                    } else {
                        this.PCMs = [];
                    }

                    if (this.mostrarOperacionais == false) {
                        this.PCMs = this.PCMs.filter(item => item.nivel !== 'Operacional');
                        this.PCMs = this.PCMs.filter(item => item.status !== 'Aprovação Técnica')
                    }

                    if (this.mostrarAprovados == false) {
                        this.SolicitaçãoEncerramento = this.SolicitaçãoEncerramento.filter(item => item.status !== 'Aprovado' && item.status !== 'Reprovado')
                        this.PCMs = this.PCMs.filter(item => item.status !== 'Aprovado' && item.status !== 'Reprovado')
                    }
                    this.filtrarPCMs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        verPCM(id) {
            this.$router.push({ name: "PCM", params: { idPCM: id } })
        },

        async getSetores() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.usuarios = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>

<style scoped>
td,
th,
tr {
    text-align: center;
}

.button {
    width: 7rem;
    padding: 5px !important;
    border: none !important;
    border-radius: 6px !important;
    cursor: pointer;
    color: var(--cor-fonte);
}

.reprovar {
    background-color: #e02130;
}

.aprovar {
    background-color: #429867;
}

.reprovar:disabled {
    background-color: #e0213171 !important;
    cursor: not-allowed !important;
}


.aprovar:disabled {
    background-color: #4298677e !important;
    cursor: not-allowed !important;
}

.aprovar:hover,
.reprovar:hover {
    transition: 50ms linear;
    transform: scale(1.1);
}
</style>