import { createRouter, createWebHashHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "../services/permissao-service";

//Projetos
import ControleDeProjetos from '../views/Projetos/ControleDeProjetos.vue'
import ProjetoView from '../views/Projetos/ProjetoView'

//PCM
import VisualizaçãoPCM from '@/views/PCM/VisualizaçãoPCM.vue'
import ControlePCM from '@/views/PCM/ControlePCM'
import ControlePcmDemandados from '@/views/PCM/ControlePcmDemandados.vue'
import CriarPCM from '@/views/PCM/CriarPCM.vue'

//Plano de Ação
import ControledePlanodeAção from '@/views/PA/ControleDePlanodeAção.vue'
import VisualizaçãoPlanodeAção from '@/views/PA/VisualizaçãoPlanodeAção.vue'

//PAP
import ControledePAP from '@/views/PAP/ControleDePAP.vue'
import VisualizaçãoPAP from '@/views/PAP/VisualizaçãoPAP.vue'

//Programas
import controleProgramas from '@/views/Programas/ControleProgramas'

//Gestão
import GestaoDeTarefas from '@/views/Gestão/gestaoDeTarefas.vue'
import GestaoDeProjetos from '@/views/Gestão/gestaoDeProjetos.vue';

//Protocolos
import CriarProtocolo from '@/views/Protocolo/criarProtocolo.vue';
import visualizaçãoProtocolo from '@/views/Protocolo/visualizaçãoProtocolo.vue'

//Rotina
import Grupos from '@/views/Rotina/GruposGestão.vue';
import GruposGestao from '@/views/Rotina/GruposView.vue';
import ControleDeProtocolo from '@/views/Protocolo/controleDeProtocolo.vue';


// redireciona usuario para LOGIN baseado no env
function enviarUsuarioLogin() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/login";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/login";
  }
}

// redireciona usuario para PORTAL baseado no env
function enviarUsuarioPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

// apenas valida se esta logado
function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    enviarUsuarioLogin();
  }
}

// apenas valida se tem permissao
async function guardPermissao(to, from, next) {
  const permissoes = await getPermissao();
  const funcionalidadeNecessaria = to.meta.funcionalidadeId;
  if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
    enviarUsuarioPortal();
  } else {
    next();
  }
}

// combinacao permissao + verificacao logado
function guardPermissaoRoute(to, from, next) {
  guardMyroute(to, from, (guardResultado) => {
    if (guardResultado !== undefined) {
      next(guardResultado);
    } else {
      guardPermissao(to, from, next);
    }
  });
}

const routes = [

  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },
  //Rotina
  {
    path: '/rotina',
    name: 'GruposGestao',
    component: Grupos,
    beforeEnter: guardPermissaoRoute,

  },
  {
    path: '/grupo/:id',
    name: 'GrupoView',
    component: GruposGestao,
    props: route => ({
      id: route.params.id
    }),
    beforeEnter: guardPermissaoRoute,
  },

  //Protocolos
  {
    path: '/protocolo/:tipo',
    name: 'controleDeProtocolo',
    props: true,
    component: ControleDeProtocolo,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/protocolo/:idProtocolo',
    props: true,
    name: 'visualizaçãoProtocolo',
    component: visualizaçãoProtocolo,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/protocolo/criar/:tipo',
    name: 'criaçãoProtocolo',
    props: true,
    component: CriarProtocolo,
    beforeEnter: guardPermissaoRoute,
  },

  // Gestão
  {
    path: '/gestao',
    name: 'gestaoProjetos',
    component: GestaoDeProjetos,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/',
    name: 'gestaoTarefas',
    component: GestaoDeTarefas,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/PCM/:idPCM',
    name: 'PCM',
    component: VisualizaçãoPCM,
    props: route => ({
      idPCM: route.params.idPCM
    }),
    beforeEnter: guardPermissaoRoute
  },
  {
    path: '/PCM/Controle',
    name: 'ControlePCM',
    component: ControlePCM,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/PCM/Controle/Demandados',
    name: 'ControlePCMDemandados',
    component: ControlePcmDemandados,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/PCM/Criar',
    name: 'CriarPCM',
    component: CriarPCM,
    beforeEnter: guardPermissaoRoute,
  },

  //PAP
  {
    path: '/PAP',
    name: 'ControlePAP',
    component: ControledePAP,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/PAP',
    name: 'PAP',
    component: VisualizaçãoPAP,
    beforeEnter: guardPermissaoRoute
  },

  //Plano de Ação
  {
    path: '/PA/Controle',
    name: 'ControlePA',
    component: ControledePlanodeAção,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/PA',
    name: 'PA',
    component: VisualizaçãoPlanodeAção,
    beforeEnter: guardPermissaoRoute
  },

  //programas
  {
    path: '/Programas/controle',
    name: 'controleProgramas',
    component: controleProgramas,
    beforeEnter: guardPermissaoRoute,
  },

  //Projetos
  {
    path: '/projeto',
    name: 'sprints',
    component: ProjetoView,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/projetos/:id',
    props: true,
    name: 'ControleDeProjetos',
    component: ControleDeProjetos,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: '/:pathMatch(.*)*', redirect: '/'
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router