<template>
    <div class="margem container">
        <div class="bloco margem">
            <div class="grid-3">
                <div class="finance-card">
                    <h4>Orçamento Total</h4>
                    <p> {{ formatCurrency(orcamentoTotal) }}</p>
                </div>
                <div class="finance-card">
                    <h4>Custo Atual</h4>
                    <p>{{ formatCurrency(custoAtual) }}</p>
                </div>
                <div class="finance-card">
                    <h4>Saldo Disponível</h4>
                    <p>{{ formatCurrency(orcamentoTotal - custoAtual) }}</p>
                </div>
            </div>
            <br>
            <h3>Materiais Utilizados</h3>
            <div v-for="produto in produtos" :key="produto.id" class="produto-container">
                <h4>{{ produto.nome }}</h4>
                <table class="tabela">
                    <thead>
                        <tr>
                            <th>Código Material</th>
                            <th>Descrição</th>
                            <th>Valor</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="requisicao in produto.materiais" :key="requisicao.id">
                            <td>{{ requisicao.codigo }}</td>
                            <td>{{ requisicao.descricao }}</td>
                            <td> {{ formatCurrency(requisicao.valor) }}</td>
                            <td :class="statusClass(requisicao.status)">{{ requisicao.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            orcamentoTotal: 500000,
            custoAtual: 5000,
            produtos: [
                {
                    id: 1,
                    nome: 'Produto A',
                    materiais: [
                        { id: 1, codigo: 'MAT-001', descricao: 'Aço Inox', valor: 5000, status: 'Utilizado' },
                        { id: 2, codigo: 'MAT-002', descricao: 'Alumínio', valor: 3000, status: 'Em Compra' }
                    ]
                },
                {
                    id: 2,
                    nome: 'Produto B',
                    materiais: [
                        { id: 3, codigo: 'MAT-003', descricao: 'Cobre', valor: 7000, status: 'Em Compra' }
                    ]
                }
            ]
        };
    },
    methods: {
        formatCurrency(value) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        },
        statusClass(status) {
            return {
                'aprovado': status === 'Utilizado',
                'pendente': status === 'Em Compra',
            };
        }
    }
};
</script>
<style scoped>
.grid-3 {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.finance-card {
    background: var(--cor-bg);
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.tabela {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.tabela th,
.tabela td {
    border: 1px solid var(--cor-separador);
    padding: 0.5rem;
    text-align: center;
}

.aprovado {
    color: green;
}

.pendente {
    color: orange;
}

.rejeitado {
    color: red;
}

.produto-container {
    margin-bottom: 2rem;
}
</style>
