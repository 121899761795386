<template>
    <div class="titulo">
        <div class="margem container">
            <div class="m-icone esquerda">
                <a @click="this.$router.back();" class="icone-voltar m-d" title="Voltar"></a>
            </div>
            <h2>{{ rotina?.info?.nome ?? '' }}</h2>
        </div>
    </div>
    <div class="margem container">
        <div class="abas">
            <a @click="(aba != 1 ? getRotina() : null), aba = 1, salvarAba(1)"
                :class="aba == 1 ? 'ativo' : ''">Rotina</a>
            <a @click="aba = 2, salvarAba(2)" :class="aba == 2 ? 'ativo' : ''">Kanban </a>
        </div>
        <div v-if="aba == 1">
            <div class="bloco margem" style="overflow: auto; height: 23vw; align-content: end;" ref="timelineContainer">
                <div v-for="(eventos, data) in timeline" :key="data">
                    <h4 class="data-header alinha-centro">{{ formatarData(data) }}</h4>
                    <br>
                    <div class="tabela" style="display: flex; flex-flow: column;">
                        <template v-for="evento, index in eventos" :key="index">
                            <table class="tabela" v-if="evento.tipo == 'tarefa'" :id="evento.id">
                                <tbody>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Descrição</th>
                                        <th>H.P.</th>
                                        <th>Responsável</th>
                                        <th>Inicio Previsto</th>
                                        <th>Fim Previsto</th>
                                        <th>Status</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr @mouseover="mostrarBotao(evento.id, true)"
                                        @mouseleave="mostrarBotao(evento.id, false)">
                                        <td>{{ evento.codigo }}</td>
                                        <td style="min-width: 14rem; max-width: 14rem; justify-content: center;">
                                            <div :title="evento.descricao"
                                                style="width: 100%;overflow: hidden; text-overflow: ellipsis;">
                                                {{ evento.comentario }}
                                            </div>
                                        </td>
                                        <td>
                                            <select v-model="evento.HP" class="HP"
                                                @change="atualizarTarefa(evento.id, 'HP', evento.HP)"
                                                style="width: 2.5rem; height: 2.5rem;text-align: center; border-radius: 50%; padding: 0; opacity: 1;">
                                                <option hidden>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>5</option>
                                                <option>8</option>
                                                <option>13</option>
                                                <option>21</option>
                                                <option>34</option>
                                                <option>55</option>
                                                <option>89</option>
                                                <option>144</option>
                                                <option>233</option>
                                            </select>
                                        </td>
                                        <td><select v-model="evento.responsavel_id"
                                                @change="atualizarTarefa(evento.id, 'responsavel_id', evento.responsavel_id)">
                                                <option :value="null" hidden></option>
                                                <option v-for="pessoa, index in participantes" :key="index"
                                                    :value="pessoa.id">
                                                    {{ pessoa.nome }}</option>
                                            </select></td>
                                        <td>
                                            <input @change="atualizarTarefa(evento.id, 'dtInicio', evento.dtInicio)"
                                                style="background-color: transparent;cursor: pointer; width: 7rem; text-align: left; padding-inline: 0rem; border: none; opacity: 1; "
                                                type="date" v-model="evento.dtInicio">
                                        </td>
                                        <td>
                                            <input @change="atualizarTarefa(evento.id, 'dtFim', evento.dtFim)"
                                                style="background-color: transparent;cursor: pointer; width: 7rem; text-align: left; padding-inline: 0rem; border: none; opacity: 1; "
                                                type="date" v-model="evento.dtFim">
                                        </td>
                                        <td style="padding: 0;">
                                            <div style="display: flex; justify-content: center;">
                                                <div style="position: relative;">
                                                    <v-progress-linear :color="getStatusColor(evento.status)"
                                                        :model-value="getStatusProgress(evento.status)"
                                                        style="border: 1px solid var(--cor-fonte-fraca); border-radius: 25px; height: 2rem; width: 12rem;">
                                                    </v-progress-linear>
                                                    <select v-model="evento.status"
                                                        @change="atualizarTarefa(evento.id, 'status', evento.status)"
                                                        :style="{ 'color': (evento.status == 'Concluído') ? 'var(--cor-bg)' : 'black' }"
                                                        style="opacity: 1;border: none; padding-left: 0.5rem;position: absolute; top: 0; left: 0rem; outline: none; border-radius: 25px ;height: 2rem; width: 12rem; text-align: center; background-color: transparent; padding-top: 0rem; padding-bottom: 0rem;">
                                                        <option style="color: rgb(255, 145, 0);" value="0">
                                                            A iniciar
                                                        </option>
                                                        <option style="color: rgb(0, 47, 255);" value="1">
                                                            Em andamento 0%
                                                        </option>
                                                        <option style="color: rgb(0, 47, 255);" value="2">
                                                            Em andamento 25%</option>
                                                        <option style="color: rgb(0, 47, 255);" value="3">
                                                            Em andamento 50%</option>
                                                        <option style="color: rgb(0, 47, 255);" value="4">
                                                            Em andamento 75%</option>
                                                        <option style="color: rgb(0, 192, 0);" value="5">Concluído
                                                        </option>
                                                        <option style="color: var(--cor-erro);" value="6">Cancelado
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td><v-menu style="cursor: pointer;">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn :id="'botaoEdicao' + evento.id" v-bind="props"
                                                        class="botaoAdicionarSprint acao-secundaria"
                                                        icon="mdi-dots-horizontal"
                                                        style="visibility: hidden;  width: 2rem; height: 2rem; background-color: transparent; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"></v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="responderItem(evento)">Responder</v-list-item>
                                                    <v-list-item style="color: red;"
                                                        @click="excluirTarefa(evento.id)">Excluir</v-list-item>
                                                </v-list>
                                            </v-menu></td>
                                        <td><span class="hora">{{ formatarHora(evento.created_at) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="evento.tipo == 'comentario'" :id="evento.id" class="linha-tempo">
                                <div class="citação"
                                    v-if="evento.parente_id && getItemPai(evento.parente_id).tipo == 'comentario'"
                                    @click="focoCitação(evento.parente_id)">
                                    <i class="bi bi-arrow-90deg-right"></i>
                                    <div>
                                        <strong>{{ getItemPai(evento.parente_id).usuario }}</strong>:
                                        {{ getItemPai(evento.parente_id).comentario }}
                                    </div>
                                </div>
                                <div class="citação"
                                    v-if="evento.parente_id && getItemPai(evento.parente_id).tipo == 'tarefa'"
                                    @click="focoCitação(evento.parente_id)">
                                    <i class="bi bi-arrow-90deg-right"></i>
                                    <div>
                                        <strong>{{ getItemPai(evento.parente_id).codigo }}</strong>: {{
                                            getItemPai(evento.parente_id).comentario }}
                                    </div>
                                </div>
                                <div class="comentario" :id="evento.id">
                                    <div>
                                        <strong>{{ evento.usuario }}</strong>:
                                        {{
                                            evento.comentario }} <span class="hora">{{
                                            formatarHora(evento.created_at) }}</span>
                                    </div>
                                    <i class="bi bi-arrow-90deg-left" @click="responderItem(evento)"
                                        style="align-self: flex-end;" title="Responder comentário"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <br>
            <div class="bloco2 margem">
                <div class="alinha-v">
                    <v-menu style="cursor: pointer;">
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" icon="mdi-plus" style="transform: scale(0.7);"
                                class="acao-secundaria"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-if="tipoAdição != 'comentario'"
                                @click="tipoAdição = 'comentario'">Comentário</v-list-item>
                            <v-list-item v-if="tipoAdição != 'tarefa'"
                                @click="mudarTipoDeAdição('tarefa')">Tarefa</v-list-item>
                            <v-list-item @click="openFileInput">Anexo</v-list-item>
                        </v-list>
                    </v-menu>
                    <input style="display: none;" ref="fileInput" type="file">
                    <div style="width: 100%;" v-if="tipoAdição == 'comentario'">
                        <div class="citação" v-if="novaCitação">
                            <i class="bi bi-arrow-90deg-right"></i>
                            <div v-if="novaCitação.tipo !== 'tarefa'">
                                <strong>{{ novaCitação.usuario }}</strong>:
                                {{ novaCitação.comentario }}
                            </div>
                            <div v-else>
                                <strong>{{ novaCitação.codigo }}</strong>: {{ novaCitação.comentario }}
                            </div>
                        </div>
                        <input type="text"
                            @keyup.enter="adicionarComentario($event.target.value), $event.target.value = null"
                            style="height: 3rem; margin: 0; margin-left: 5px;" placeholder="Digite seu comentário..."
                            class="input-comentario" ref="inputComentario" />
                    </div>
                    <div v-else-if="tipoAdição == 'tarefa'"
                        style="display: flex; padding-left: 0.2rem; border-radius: 5px; width: 100%; align-content: center; gap: 5px;">
                        <input ref="inputTarefa" type="text" placeholder="O que será feito?" class="input-comentario"
                            style="height: 3rem; margin: 0; margin-left: 5px" v-model="descricaoNovaTarefa"
                            @keyup.enter="adicionarTarefa()">

                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <kanban-component :tarefas="rotina.itens.filter(i => i.tipo == 'tarefa')" @atualizarTarefa="atualizarTarefa" />
        </div>
    </div>
</template>
<script>
import { sso } from "roboflex-thalamus-sso-lib";
import KanbanComponent from "@/components/Kanban/KanbanComponent.vue";
import serviceRotina from "@/services/serviceRotina"
export default {
    name: "GestaoGrupos",
    props: {
        id: {
            required: true
        },
    },
    components: {
        KanbanComponent
    },
    data() {
        return {
            tipoAdição: 'comentario',
            rotina: null,
            idUsuario: '',
            nomeUsuario: '',
            novaCitação: null,
            aba: 1,
            descricaoNovaTarefa: null,
            participantes: []
        };
    },
    mounted() {
        this.getRotina()
    },
    created() {
        const usuarioLogado = sso.getUsuarioLogado();
        this.idUsuario = usuarioLogado.id;
        this.nomeUsuario = usuarioLogado.nome;
        this.alinharScroll()
    },
    computed: {
        timeline() {
            if (this.rotina) {
                let eventosOrdenados = this.rotina.itens
                eventosOrdenados.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                return eventosOrdenados.reduce((acc, evento) => {
                    const dataEvento = new Date(evento.created_at);
                    const ano = dataEvento.getFullYear();
                    const mes = String(dataEvento.getMonth() + 1).padStart(2, '0');
                    const dia = String(dataEvento.getDate()).padStart(2, '0');
                    const dataFormatada = `${ano}-${mes}-${dia}`;
                    if (!acc[dataFormatada]) acc[dataFormatada] = [];
                    acc[dataFormatada].push(evento);
                    return acc;
                }, {});
            } else {
                return null
            }
        },
    },
    methods: {
        getItemPai(id) {
            return this.rotina.itens.find(item => item.id == id)
        },
        async getRotina() {
            var response = await serviceRotina.getRotina(this.id)
            this.participantes = response.participantes
            response.itens.forEach(evento => {
                if (evento.tipo === 'tarefa') {
                    if (evento.dtInicio) {
                        evento.dtInicio = evento.dtInicio.split(' ')[0];
                    }
                    if (evento.dtFim) {
                        evento.dtFim = evento.dtFim.split(' ')[0];
                    }
                }
            });
            this.rotina = response;
            this.alinharScroll()
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        responderItem(item) {
            this.novaCitação = item;
            this.tipoAdição = 'comentario',
                this.$nextTick(() => {
                    if (this.$refs.inputComentario) {
                        this.$refs.inputComentario.focus();
                    }
                });
        },
        excluirTarefa(id) {
            this.rotina = this.rotina.filter(i => i.id !== id)
            serviceRotina.excluirTarefa(id)
        },
        atualizarTarefa(idTarefa, itemEditado, valor) {
            var payload = {
                [itemEditado]: valor
            }
            serviceRotina.atualizarTarefa(idTarefa, payload)
        },
        async adicionarTarefa() {
            var payload = {
                codigo: `Tarefa - ${this.rotina.itens.filter(i => i.tipo == 'tarefa').length + 1}`,
                descricao: this.descricaoNovaTarefa,
                protocolo_id: this.id,
                status: '0'
            }
            this.rotina.itens.push({
                ...payload,
                comentario: this.descricaoNovaTarefa,
                HP: 0,
                created_at: new Date().toISOString(),
                tipo: 'tarefa'
            });
            this.descricaoNovaTarefa = null;
            var response = await serviceRotina.adicionarTarefa(payload)
            if (response) {
                if (this.rotina && this.rotina.itens.length > 0) {
                    this.rotina.itens[this.rotina.itens.length - 1].id = response.id;
                }
            }
            if (!response) {
                this.getRotina();
                return
            }
            this.alinharScroll();
            this.tipoAdição = 'comentario';
            this.$nextTick(() => {
                if (this.$refs.inputComentario) {
                    this.$refs.inputComentario.focus();
                }
            });
        },
        async adicionarComentario(comentario) {
            var payload = {
                protocolo_id: this.id,
                comentario: comentario,
                comentario_id: this.novaCitação?.tipo == 'comentario' ? this.novaCitação?.id : null,
                protocoloTarefa_id: this.novaCitação?.tipo == 'tarefa' ? this.novaCitação?.id : null,
            }
            this.rotina.itens.push({
                ...payload,
                parente_id: payload.comentario_id ?? payload.protocoloTarefa_id ?? null,
                usuario: this.nomeUsuario,
                created_at: new Date().toISOString(),
                tipo: 'comentario'
            })
            this.novaCitação = null;
            this.alinharScroll()
            var response = await serviceRotina.adicionarComentario(payload);
            if (response) {
                if (this.rotina && this.rotina.itens.length > 0) {
                    this.rotina.itens[this.rotina.itens.length - 1].id = response.id;
                }
            }
            if (!response) {
                this.getRotina();
                return
            }
        },
        mudarTipoDeAdição(tipo) {
            this.tipoAdição = tipo;
            this.$nextTick(() => {
                if (this.$refs.inputTarefa) {
                    this.$refs.inputTarefa.focus();
                }
            });
        },
        salvarAba(id) {
            localStorage.setItem('ultimaAbaRotina', id)
        },
        alinharScroll() {
            this.$nextTick(() => {
                const container = this.$refs.timelineContainer;
                container.scrollTop = container.scrollHeight;
            });
        },
        focoCitação(id) {
            const elemento = document.getElementById(id);
            elemento.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            setTimeout(() => {
                elemento.classList.remove('destaque');
                void elemento.offsetWidth;
                elemento.classList.add('destaque');
            }, 200);
        },

        formatarData(data) {
            if (!data || typeof data !== 'string' || isNaN(new Date(data).getTime())) {
                return 'Data inválida';
            }
            const dataEvento = new Date(data + "T00:00:00");
            const hoje = new Date();
            const ontem = new Date();
            ontem.setDate(ontem.getDate() - 1);
            if (
                dataEvento.getDate() === hoje.getDate() &&
                dataEvento.getMonth() === hoje.getMonth() &&
                dataEvento.getFullYear() === hoje.getFullYear()
            ) {
                return "Hoje";
            }
            if (
                dataEvento.getDate() === ontem.getDate() &&
                dataEvento.getMonth() === ontem.getMonth() &&
                dataEvento.getFullYear() === ontem.getFullYear()
            ) {
                return "Ontem";
            }
            return dataEvento.toLocaleDateString("pt-BR", { timeZone: "America/Sao_Paulo" });
        },

        formatarHora(timestamp) {
            if (!timestamp || typeof timestamp !== 'string' || isNaN(new Date(timestamp).getTime())) {
                return 'Hora inválida';
            }
            const dataEvento = new Date(timestamp);
            return dataEvento.toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "America/Sao_Paulo"
            });
        },
        mostrarBotao(id, mostrar) {
            document.getElementById('botaoEdicao' + id).style.visibility = mostrar ? '' : 'hidden';
        },
        getStatusColor(status) {
            switch (status) {
                case '0': return 'var(--cor-alerta)';
                case '1': return 'var(--cor-ok)';
                case '2': return 'var(--cor-ok)';
                case '3': return 'var(--cor-ok)';
                case '4': return 'var(--cor-ok)';
                case '5': return 'var(--cor-sucesso)';
                case '6': return 'var(--cor-erro)';
                default: return 'gray';
            }
        },
        getStatusProgress(status) {
            switch (status) {
                case '0': return 0;
                case '1': return 0;
                case '2': return 25;
                case '3': return 50;
                case '4': return 75;
                case '5': return 100;
                case '6': return 100;
                default: return 0;
            }
        }
    }
};
</script>
<style scoped>
.abas {
    display: flex;
    height: 2.5rem;
    margin-left: 1.5rem;
    align-items: end;

    a {
        padding: .5em 1em;
        border-radius: 12px 12px 0 0;
        border: 1px solid var(--cor-separador);
        border-bottom: none;
        align-content: center;
    }

    .ativo {
        background-color: var(--cor-primaria);
        color: var(--cor-bg);
        height: 2.8rem;
        transition: all 400ms ease-in-out;
    }
}

.citação {
    display: flex;
    cursor: pointer;
    opacity: 0.7;

    i {
        font-size: 16px;
        margin-top: 3px;
    }

    strong {
        margin-left: .2rem;
    }
}

.input-comentario {
    height: 3rem;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.comentario {
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}

.hora {
    font-size: 12px;
    color: gray;
    margin-left: 10px;
}

.data-header {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
}

.linha-tempo {
    margin-bottom: 10px;
    padding: 10px;
    border-left: 4px solid var(--cor-separador);
}

.comentario {
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: 8px;
    border-radius: 5px;
    margin: 5px 0;

    i:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
}

.tarefa {
    padding-right: .5rem;
    margin-block: .5rem;
}

.destaque {
    animation: destaqueAnim 1s ease-in-out;
}

@keyframes destaqueAnim {
    from {
        background-color: var(--cor-separador);
    }

    to {
        background-color: #f4f4f4;
    }
}
</style>