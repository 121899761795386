<template>
    <div>
        <div>
            <div style="text-align: center;">
                <h3>Área do Solicitante</h3>
            </div>
            <fieldset class="bloco2 margem">
                <div class="grid-4">
                    <div class="col-2">
                        <label>Nome do {{ PCM.tipo }}</label>
                        <input @focusout="atualizarPCM('nome', PCM.nome)" :class="{ shake: disabled }" id="nome"
                            v-model="PCM.nome" type="text" />
                    </div>
                    <div>
                        <label>Nível</label>
                        <select v-model="PCM.nivel" @change="atualizarPCM('nivel', PCM.nivel)">
                            <option :hidden="PCM.tipo == 'Plano de Ação'">Estratégico</option>
                            <option>Operacional</option>
                        </select>
                    </div>
                    <div>
                        <label>Tipo</label>
                        <select v-model="PCM.tipo" @change="atualizarPCM('tipo', PCM.tipo)">
                            <option>Programa</option>
                            <option>Projeto</option>
                            <option :hidden="nivel == 'Estratégico'">PAP</option>
                        </select>
                    </div>
                    <div>
                        <label>Finalidade</label>
                        <select v-model="PCM.finalidade" @change="atualizarPCM('finalidade', PCM.finalidade)">
                            <option>Criação</option>
                            <option>Mudança</option>
                        </select>
                    </div>
                    <div>
                        <label>Área</label>
                        <select v-model="PCM.area" @change="atualizarPCM('area', PCM.area)">
                            <option>Processo</option>
                            <option>Produto</option>
                        </select>
                    </div>
                    <div>
                        <label>Setor aprovador</label>
                        <select :class="{ shake: disabled }" id="setor_id" v-model="PCM.setor_id"
                            @change="atualizarPCM('setor_id', PCM.setor_id)" style="text-align: left;">
                            <template v-if="nivel !== 'Estratégico'">
                                <option v-for="item in setores" :key="item.id" :value="item.id"
                                    :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                    <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                        &nbsp;&nbsp;
                                    </span>
                                    {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                    {{ item.nome }}
                                </option>
                            </template>
                            <template v-else>
                                <option :value="7">
                                    Diretoria
                                </option>
                            </template>
                        </select>
                    </div>
                    <div>
                        <label>Data de abertura</label>
                        <input v-model="PCM.dtInicio" type="date" @focusout="atualizarPCM('dtInicio', PCM.dtInicio)" />
                    </div>
                    <div v-if="PCM.tipo == 'Programa'">
                        <label>Descrição do Problema</label>
                        <textarea :title="PCM.descricao_problema" v-model="PCM.descricao_problema"
                            @focusout="atualizarPCM('descricao_problema', descricao_problema)"></textarea>
                    </div>
                    <div v-if="PCM.tipo == 'Programa'">
                        <label>Possível solução</label>
                        <textarea :title="PCM.possivel_solucao" v-model="PCM.possivel_solucao"
                            @focusout="atualizarPCM('possivel_solucao', PCM.possivel_solucao)"></textarea>
                    </div>
                    <div class="col-4">
                        <label>Setores Afetados</label>
                        <div class="grid-4">
                            <div v-for="setor, index in PCM.setores_envolvidos" :key="index">
                                <label>{{ setor.nome }} <i @click="removerSetorEnvolvido(setor.id)"
                                        class="bi bi-x-circle" style="color: var(--cor-erro); cursor: pointer;"
                                        title="Remover setor"></i></label>
                                <textarea placeholder="justificativa"
                                    @focusout="atualizarPCM('setores_envolvidos', PCM.setores_envolvidos.map(i => ({ setor_id: i.id, justificativa: i.justificativa ?? '' })))"
                                    v-model="setor.justificativa"></textarea>
                            </div>
                            <div style="display: flex; flex-flow: column; justify-content: end;">
                                <select style="text-align: left;"
                                    @change="!PCM.setores_envolvidos.map(i => i.id).includes(novoSetorEnvolvido.id) ? adicionarSetorEnvolvido() : novoSetorEnvolvido = null"
                                    v-model="novoSetorEnvolvido">
                                    <option :value="null" hidden>Adicionar Setor</option>
                                    <option v-for="item in setores" :key="item.id" :value="item"
                                        :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                        <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                            &nbsp;&nbsp;
                                        </span>
                                        {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                        {{ item.nome }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <label>Objetivos Estratégicos</label>
                        <div class="grid-2">
                            <div v-for="i, index in this.PCM.objetivos_estrategicos" :key="index">
                                <label>
                                    <input type="checkbox" v-model="i.ativo"
                                        @click="i.ativo = !i.ativo, atualizarPCM('objetivos', payloadObjetivos())">
                                    {{ i.nome }}
                                </label>
                                <textarea v-if="i.ativo" @focusout="atualizarPCM('objetivos', payloadObjetivos())"
                                    placeholder="Justificativa" v-model="i.justificativa"></textarea>
                            </div>
                        </div>
                    </div>
                    <div v-if="dadosUsuario?.includes(110) && PCM.tipo !== 'Programa'" class="col-4">
                        <label>Escopo</label>
                        <textarea :title="PCM.escopo" v-model="PCM.escopo"
                            @focusout="atualizarPCM('escopo', PCM.escopo)"></textarea>
                    </div>
                </div>
                <br>
                <table v-if="PCM.tipo !== 'Programa'" class="tabela"
                    style="border-bottom: 1px solid var(--cor-separador);">
                    <tbody>
                        <tr>
                            <th scope="col">Análise de impactos e viabilidade da proposta</th>
                            <th scope="col">Concordo</th>
                            <th scope="col">Justificativa</th>
                        </tr>
                        <tr v-for="(item, index) in PCM.impacto_viabilidade" :key="index">
                            <td>
                                <div>
                                    {{ item.descricao }}
                                </div>
                            </td>
                            <td style="text-align: center; padding: 0;">
                                <div class="tags" style="justify-content: center;">
                                    <a @click="(item.concordo = true), atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                        :class="item.concordo == true ? 'ativo' : ''">Sim</a>
                                    <a @click="(item.concordo = false), atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                        :class="item.concordo == false ? 'ativo' : ''">Não</a>
                                </div>
                            </td>
                            <td style="padding: 1rem; width: 30%;">
                                <textarea v-model="item.justificativa" rows="3" :class="{ shake: disabled }"
                                    :id="'justificativa' + index" :title="item.justificativa"
                                    @change="atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"></textarea>
                            </td>
                        </tr>
                        <tr v-if="dadosUsuario?.includes(110)">
                            <td>
                                <div>Estimativa de Custo da Mudança</div>
                            </td>
                            <td>
                                <money3 :class="{ shake: disabled }" v-model="PCM.estimativa_custo"
                                    @focusout="atualizarPCM('estimativa_custo', PCM.estimativa_custo)" v-bind="config"
                                    style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                                </money3>
                            </td>
                            <td style="padding: 1rem">
                                <textarea :class="{ shake: disabled }" v-model="PCM.custo_justificativa" rows="3"
                                    :title="PCM.custo_justificativa" id="custo_justificativa"
                                    @change="atualizarPCM('custo_justificativa', PCM.custo_justificativa)"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="PCM.tipo !== 'Programa'"
                    style="border: 1px solid var(--cor-separador); padding: 5px; width: 100%; border-radius: 10px; margin-top: 0.5rem;">
                    <span>
                        Confirmo que o cenário geral do mercado é viável à proposta, pois
                        apresenta
                        condições favoráveis que podem sustentar o sucesso da iniciativa.
                        Além
                        disso, a
                        empresa possui capacidade interna necessária para implementá-la,
                        contando com
                        recursos humanos qualificados e infraestrutura adequada. Com isso,
                        demonstra-se
                        que
                        a implementação da proposta será rentável à empresa, gerando
                        retornos
                        positivos.
                    </span>
                </div>
                <fieldset v-if="PCM.tipo !== 'Programa'" class="grid-3 margem">
                    <div>
                        <label>Data prevista de implementação</label>
                        <input :class="{ shake: disabled }" v-model="PCM.dtEstimadaFinal" type="date"
                            id="dtEstimadaFinal" @change="atualizarPCM('dtEstimadaFinal', PCM.dtEstimadaFinal)" />
                    </div>
                    <div>
                        <label>Data limite de implementação</label>
                        <input :class="{ shake: disabled }" v-model="PCM.dtLimiteImplementacao" type="date"
                            id="dtLimiteImplementacao"
                            @change="atualizarPCM('dtLimiteImplementacao', PCM.dtLimiteImplementacao)" />
                    </div>
                    <div>
                        <label>Gerente responsável</label>
                        <select :class="{ shake: disabled }" v-model="PCM.gerentePCM_id" id="gerentePCM_id"
                            @change="atualizarPCM('gerentePCM_id', PCM.gerentePCM_id)">
                            <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                {{ (item.nomeCompleto) }}
                            </option>
                        </select>
                    </div>
                </fieldset>
                <div class="grid-2 margem">
                    <div>
                        <label>Responsável pela solicitação</label>
                        <select disabled v-model="PCM.solicitante_id" :class="{ shake: disabled }" id="solicitante_id"
                            @change="atualizarPCM('solicitante_id', PCM.solicitante_id)">
                            <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                {{ (item.nomeCompleto) }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label>
                            Setor demandante
                        </label>
                        <select style="text-align: left" v-model="PCM.setorDemanda_id"
                            @change="atualizarPCM('setorDemanda_id', PCM.setorDemanda_id)">
                            <option v-for="item in setores" :key="item.id" :value="item.id"
                                :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                    &nbsp;&nbsp;
                                </span>
                                {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                {{ item.nome }}
                            </option>
                        </select>
                    </div>
                </div>
            </fieldset>
        </div>
        <br>
        <div>
            <div style="text-align: center">
                <h3>Área do Responsável</h3>
            </div>
            <fieldset class="bloco2 margem">
                <table class="tabela" style="border-bottom: 1px solid var(--cor-separador);"
                    v-if="dadosUsuario?.includes(110) && PCM.tipo == 'Programa'">
                    <tr>
                        <td>
                            <div>Estimativa de Custo da Mudança</div>
                        </td>
                        <td>
                            <money3 :class="{ shake: disabled }" v-model="PCM.estimativa_custo"
                                @focusout="atualizarPCM('estimativa_custo', PCM.estimativa_custo)" v-bind="config"
                                style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                            </money3>
                        </td>
                        <td style="padding: 1rem">
                            <textarea :class="{ shake: disabled }" v-model="PCM.custo_justificativa" rows="3"
                                :title="PCM.custo_justificativa" id="custo_justificativa"
                                @change="atualizarPCM('custo_justificativa', PCM.custo_justificativa)"></textarea>
                        </td>
                    </tr>
                </table>
                <div class="margem" :class="PCM.tipo == 'Programa' ? 'grid-3' : 'grid-2'">
                    <div v-if="PCM.tipo == 'Programa'">
                        <label>Data prevista de implementação</label>
                        <input :class="{ shake: disabled }" v-model="PCM.dtEstimadaFinal" type="date"
                            id="dtEstimadaFinal" @change="atualizarPCM('dtEstimadaFinal', PCM.dtEstimadaFinal)" />
                    </div>
                    <div v-if="PCM.tipo == 'Programa'">
                        <label>Data limite de implementação</label>
                        <input :class="{ shake: disabled }" v-model="PCM.dtLimiteImplementacao" type="date"
                            id="dtLimiteImplementacao"
                            @change="atualizarPCM('dtLimiteImplementacao', PCM.dtLimiteImplementacao)" />
                    </div>
                    <div>
                        <label>Parecer</label>
                        <select :class="{ shake: disabled }" v-model="PCM.parecer_responsavel" id="parecer_responsavel"
                            @change="atualizarPCM('parecer_responsavel', PCM.parecer_responsavel)"
                            :style="{ color: PCM.parecer_responsavel == 'Proposta viável' ? 'var(--cor-sucesso)' : 'var(--cor-erro)' }">
                            <option style="color: var(--cor-sucesso)">Proposta viável</option>
                            <option style="color: var(--cor-erro)">Proposta inviável</option>
                        </select>
                    </div>
                    <div>
                        <label> Nome do Aprovador </label>
                        <select :class="{ shake: disabled }" v-model="PCM.responsavel_id" id="responsavel_id"
                            @change="atualizarPCM('responsavel_id', PCM.responsavel_id)">
                            <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                {{ (item.nomeCompleto) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2" v-if="PCM.tipo !== 'Programa'">
                        <label>Resultado esperado</label>
                        <textarea :class="{ shake: disabled }" v-model="PCM.resultado_esperado" id="resultado_esperado"
                            :title="PCM.resultado_esperado"
                            @focusout=" atualizarPCM('resultado_esperado', PCM.resultado_esperado)"></textarea>
                    </div>
                </div>
                <div class="alinha-v alinha-centro margem">
                    <button class="button aprovar acao-secundaria bg-sucesso" :disabled="PCM.status == 'Aprovado' || !this.PCM.gerentePCM_id"
                        @click="PCM.status == 'Aprovação Técnica' ? null : criarElemento(), atualizarStatus(PCM.status == 'Aprovação Técnica' ? 'Aprovação Estratégica' : 'Aprovado')">
                        {{ PCM.status == 'Aprovado' ? 'Aprovado' : PCM.status == 'Aprovação Técnica' ?
                            'Aprovação Técnica' :
                            'Aprovar' }}
                    </button>
                    <button class="button reprovar acao-secundaria bg-erro" @click="avisoExclusao()"
                        :disabled="PCM.status == 'Reprovado'">
                        {{ PCM.status == 'Reprovado' ? 'Reprovado' : 'Reprovar' }}
                    </button>
                </div>
            </fieldset>
        </div>
    </div>

    <!-- modal -->
    <div style="overflow: auto" class="modal-mask" v-if="modalConfirmacao" @click="fecharModalFora">
        <div style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;" class="jm">
            <div style="color: var(--cor-fonte);">
                <div style="display: flex; justify-content: space-between">
                    <h1>Deseja reprovar {{ this.PCM.codigo }}?</h1>
                </div>
                <h3>O {{ this.PCM.tipo }} associado ao PCM que você está
                    reprovando será excluido.<br>
                    Atualmente ele possui
                    <strong>
                        {{ quantidadeTarefas }}
                    </strong>
                    {{ quantidadeTarefas > 1 ? 'tarefas registradas.' : 'tarefa registrada.' }}
                </h3>

                <div class="modal-footer">
                    <button
                        @click="excluirElemento(), atualizarStatus('Reprovado'), modalConfirmacao = false">Confirmar</button>
                    <button class="acao-secundaria" @click="modalConfirmacao = false"
                        style="margin-left: 0.5rem;">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- end-modal -->
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissao-service";
import { Money3Component } from "v-money3";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
export default {
    name: "pcmComponent",
    components: { money3: Money3Component },
    props: {
        idPCM: {
            required: true
        }
    },
    data() {
        return {
            dadosUsuario: null,
            modalConfirmacao: false,
            idUsuario: null,
            quantidadeTarefas: null,
            disabled: false,

            setores: '',
            usuarios: null,

            novoSetorEnvolvido: null,
            PCM: {},

            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },
        };
    },

    async created() {
        this.dadosUsuario = await getPermissao();
        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id,

            this.getSetores(),
            this.getPCM()
    },

    methods: {
        removerSetorEnvolvido(id) {
            this.PCM.setores_envolvidos = this.PCM.setores_envolvidos.filter(i => i.id !== id)
            var payload = this.PCM.setores_envolvidos.map(i => ({ setor_id: i.id, justificativa: i.justificativa ?? '' }));
            this.atualizarPCM('setores_envolvidos', payload);
        },
        adicionarSetorEnvolvido() {
            this.PCM.setores_envolvidos.push(this.novoSetorEnvolvido);
            var payload = this.PCM.setores_envolvidos.map(item => ({
                setor_id: item.id,
                justificativa: item.justificativa ?? ''
            }));
            this.atualizarPCM('setores_envolvidos', payload);
            this.novoSetorEnvolvido = null
        },
        payloadObjetivos() {
            return this.PCM.objetivos_estrategicos.filter(item => item.ativo == true).map(i => ({ objetivo_id: i.id, justificativa: i.justificativa }))
        },

        avisoExclusao() {
            if (this.PCM.tipo == 'Projeto') {
                api.get('projeto/listar').then((response) => {
                    var projetoEncontrado = response.data.find(projeto => projeto.id === this.PCM.id_associacao);
                    if (projetoEncontrado) {
                        var quantidadeTarefas = projetoEncontrado.quantidade_tarefas;
                        if (quantidadeTarefas !== 0) {
                            this.modalConfirmacao = true;
                            this.quantidadeTarefas = quantidadeTarefas;
                            this.tipo = this.PCM.tipo;
                        } else {
                            this.excluirElemento();
                            this.atualizarStatus('Reprovado');
                        }
                    } else {
                        this.excluirElemento();
                        this.atualizarStatus('Reprovado');
                    }
                }).catch(error => {
                    // Tratar erros de requisição
                    console.error('Erro ao buscar projetos:', error);
                });
            }
            if (this.PCM.tipo == 'Plano de Ação') {
                api.get('planoAcao/listar').then((response) => {
                    var planoEncontrado = response.data.find(plano => plano.id === this.PCM.id_associacao);
                    if (planoEncontrado) {
                        var quantidadeTarefas = planoEncontrado.quantidade_tarefas;
                        if (quantidadeTarefas !== 0) {
                            this.modalConfirmacao = true;
                            this.quantidadeTarefas = quantidadeTarefas;
                            this.tipo = this.PCM.tipo;
                        } else {
                            this.excluirElemento();
                            this.atualizarStatus('Reprovado');
                        }
                    } else {
                        // this.excluirElemento();
                        this.atualizarStatus('Reprovado');
                    }
                }).catch(error => {
                    // Tratar erros de requisição
                    console.error('Erro ao buscar planos de ação:', error);
                });
            }
            if (this.PCM.tipo == 'Programa') {
                this.excluirElemento()
                this.atualizarStatus('Reprovado')
            }
        },

        excluirElemento() {
            if (this.PCM.tipo == 'Projeto') {
                api.put(`projeto/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (this.PCM.tipo == 'Plano de Ação' || this.PCM.tipo == 'PAP') {
                api.put(`planoAcao/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (this.PCM.tipo == 'Programa') {
                api.put(`programa/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        criarElemento() {
            var responsavelSetorDemandante = this.setores.find((setor) => setor.id == this.PCM.setorDemanda_id).responsavel_id

            if (!responsavelSetorDemandante) {
                console.error('Responsavel pelo setor demandante não encontrado')
                return
            }

            if (this.PCM.id_associacao == null) {
                if (this.PCM.tipo == 'Projeto') {
                    api.post(`projeto/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        demandante_id: responsavelSetorDemandante,
                        setor_id: this.PCM.setor_id,
                        usuario_id: this.idUsuario,
                        solicitante_id: this.PCM.solicitante_id,
                        status: "Em andamento"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                // projeto: 1,
                                projeto_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (this.PCM.tipo == 'Plano de Ação' || this.PCM.tipo == 'PAP') {
                    api.post(`planoAcao/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        demandante_id: responsavelSetorDemandante,
                        setor_id: this.PCM.setor_id,
                        usuario_id: this.idUsuario,
                        solicitante_id: this.PCM.solicitante_id,
                        status: "Em andamento"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                // projeto: 0,
                                planoAcao_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (this.PCM.tipo == 'Programa') {
                    api.post(`programa/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        status: "Em andamento"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                programa_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
        },

        atualizarStatus(valor) {
            api.put(`pcm/atualizar/${this.idPCM}`, {
                status: valor,
                aprovada: valor == 'Aprovado' ? 1 : 0
            })
                .then(() => {
                    this.getPCM()
                })
                .catch((error) => {
                    console.error(error);
                });
            if (valor == 'Aprovação Estratégica') {
                this.$router.push('/PCM/Controle')
            }
        },

        objetoSemIndiceDescricao(item) {
            if (item) {
                const { indice, descricao, ...resto } = item;
                indice
                descricao
                return [resto];
            }
        },

        atualizarPCM(itemEditado, valor) {
            api.put(`pcm/atualizar/${this.idPCM}`, {
                [itemEditado]: valor,
            })
                .then(() => { })
                .catch((error) => {
                    console.error(error);
                });

        },

        async getSetores() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.usuarios = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        getPCM() {
            api.get(`pcm/buscar/${this.idPCM}`, {})
                .then(response => {
                    this.PCM = response.data
                    if (this.PCM.nivel == 'Estratégico') {
                        this.PCM.responsavel_id = 15
                    }
                    this.PCM.objetivos_estrategicos = this.PCM.objetivos_estrategicos.map(item => ({
                        ...item,
                        ativo: item.ativo == 1 ? true : false
                    }))
                })

                .catch((error) => {
                    console.error(error);
                });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalConfirmacao = false;
            }
        },
    },
};
</script>

<style scoped>
.objetivos {
    display: flex;
    flex-flow: column;

}

a {
    cursor: pointer;
}

input:disabled,
select:disabled,
textarea:disabled {
    opacity: 1;
    cursor: not-allowed;
}

.button {
    /* width: 7rem;
    padding: 5px !important;
    border: none !important;
    border-radius: 6px !important; */
    cursor: pointer;
    color: var(--cor-fonte);
    margin-inline: 0.5rem;
}

.reprovar {
    background-color: #e02130;
}

.aprovar {
    background-color: #429867;
}

.reprovar:disabled {
    background-color: #e0213171 !important;
    cursor: not-allowed !important;
}


.aprovar:disabled {
    background-color: #4298677e !important;
    cursor: not-allowed !important;
}

.aprovar:hover,
.reprovar:hover {
    transition: 50ms linear;
    transform: scale(1.1);
}
</style>