<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ protocolo.tipo_nome }} - {{ protocolo.codigo }}</h2>
                </div>
            </div>
            <div class="margem container">
                <fieldset class="grid-4 bloco2 margem">
                    <div><label>Tipo</label><select class="form-select" disabled v-model="protocolo.tipo_id">
                            <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                        </select></div>
                    <div><label>Nome</label><input type="text" v-model="protocolo.nome"
                            @focusout="editarProtocolo('nome', protocolo.nome)"></div>
                    <div><label>Setor</label><select v-model="protocolo.setor_id"
                            @change="editarProtocolo('setor_id', protocolo.setor_id)">
                            <option v-for="item in setores" :key="item.id" :value="item.id"
                                :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                    &nbsp;&nbsp;
                                </span>
                                {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                {{ item.nome }}
                            </option>
                        </select></div>
                    <div><label>Responsável</label><select
                            @change="editarProtocolo('responsavel_id', protocolo.responsavel_id)"
                            v-model="protocolo.responsavel_id">
                            <option v-for="item in gerente" :key="item.id" :value="item.id">
                                {{ nomeEsobrenome(item.nomeCompleto) }}
                            </option>
                        </select></div>
                    <div><label>Data</label><input type="date" v-model="protocolo.dtInicio"
                            @change="editarProtocolo('dtInicio', protocolo.dtInicio)"></div>
                    <div><label>Hora</label><input type="time" v-model="protocolo.horaInicio"
                            @focusout="editarProtocolo('horaInicio', protocolo.horaInicio)"></div>

                    <div class="col-2"><label>Anexos: <i title="Adicionar anexo"
                                style="font-size: 15px; cursor: pointer; margin-left: 0.5rem;" class="bi bi-plus-circle"
                                @click="openFileInput"></i></label>
                        <div class="grid-2">
                            <div class="anexo" v-for="anexo in anexos" :key="anexo.id"
                                @mouseover="mostrarBotaoExcluirAnexo(anexo.id, true)"
                                @mouseleave="mostrarBotaoExcluirAnexo(anexo.id, false)">
                                <a :href="urlFoto.caminhoFoto + anexo.anexo" target="_blank">
                                    {{ anexo.nome }}</a>
                                <i @click="excluirAnexo(anexo.id)" :id="'botaoExcluir' + anexo.id"
                                    style="color: red; visibility: hidden;"
                                    class="bi bi-trash-fill botaoAdicionarSprint"></i>
                            </div>
                        </div>
                        <input style="display: none;" ref="fileInput" class="form-control form-control-sm" type="file"
                            @change="handleFileUpload">
                    </div>
                    <div class="col-4">
                        <label>Observação</label>
                        <textarea @focusout="editarProtocolo('obs', protocolo.obs)" v-model="protocolo.obs"></textarea>
                    </div>
                </fieldset>
                <br>
                <div class="bloco margem">
                    <div class="row">
                        <div style="width: 20%;">
                            <div style="width: 10rem;">
                                <h5>
                                    <span style="display: flex;">
                                        <span class="hPoints bg-alerta">{{ somarHP(protocolo)[0] }}</span>
                                        <span class="hPoints bg-ok">{{ somarHP(protocolo)[1] }}</span>
                                        <span class="hPoints bg-sucesso">{{ somarHP(protocolo)[2] }}</span>
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <table :id="protocolo.nome" class="tabela">
                        <tr>
                            <th>
                                <strong>
                                    Código
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Descrição
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    H.P.
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Responsável
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Inicio Previsto
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Fim Previsto
                                </strong>
                            </th>
                            <th style="padding: 0rem;">
                                <strong>
                                    Status
                                </strong>
                            </th>
                            <th>
                            </th>
                        </tr>
                        <tr v-for="element in protocolo.tarefas" :key="element.id"
                            @mouseover="mostrarBotao(element.id, true)" @mouseleave="mostrarBotao(element.id, false)">

                            <td>
                                <span style="width: 100%; white-space: nowrap;">{{
                                    element.codigo }}</span>
                            </td>

                            <td class="clicavel" style="text-align: left;">
                                <div @click="abrirModalEditarBacklog(element.id)" :title="element.descricao"
                                    style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                                    {{ element.descricao }}
                                </div>
                            </td>

                            <td class="clicavel">
                                <select v-model="element.HP" class="HP"
                                    @change="editarBacklog('HP', element.id, element.HP)"
                                    style="width: 2.5rem; height: 2.5rem;text-align: center; border-radius: 50%; padding: 0;">
                                    <option hidden>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>5</option>
                                    <option>8</option>
                                    <option>13</option>
                                    <option>21</option>
                                    <option>34</option>
                                    <option>55</option>
                                    <option>89</option>
                                    <option>144</option>
                                    <option>233</option>
                                </select>
                            </td>

                            <td class="clicavel">
                                <select v-model="element.responsavel_id"
                                    style="min-width: max-content; border: none; background-color: transparent;"
                                    @change="editarBacklog('responsavel_id', element.id, element.responsavel_id)">
                                    <option hidden>Responsável</option>
                                    <option v-for="item in gerente" :key="item.id" :value="item.id">
                                        {{ nomeEsobrenome(item.nomeCompleto) }}
                                    </option>
                                </select>
                            </td>

                            <td class="clicavel">
                                <input
                                    style="cursor: pointer; width: 7rem; text-align: left; padding-inline: 0rem; border: none; background-color: transparent;"
                                    type="date" @change="editarBacklog('dtInicio', element.id, element.dtInicio)"
                                    v-model="element.dtInicio">
                            </td>

                            <td class="clicavel">
                                <input
                                    style="cursor: pointer; width: 7rem; text-align: left; padding: 0rem; border: none; background-color: transparent;"
                                    type="date" :min="element.dtInicio"
                                    @change="editarBacklog('dtFim', element.id, element.dtFim)" v-model="element.dtFim">
                            </td>

                            <td style="max-width: 14rem; min-width: 10.4rem ;padding: 0rem;">
                                <div style="position: relative;">
                                    <v-progress-linear
                                        :color="(element.status == 'Concluído') ? 'var(--cor-sucesso)' : (element.status == 'Pendente') ? 'var(--cor-alerta)' : (element.status == 'Em andamento' || 'Em andamento 25%' || 'Em andamento 50%' || 'Em andamento 75%') ? 'var(--cor-ok)' : 'red'"
                                        :model-value="element.status == 'Concluído' ? 100 : element.status == 'Pendente' ? 100 : element.status.split(' ')[2] ? element.status.split(' ')[2].slice(0, 2) : 2"
                                        style="border: 1px solid var(--cor-fonte-fraca); border-radius: 25px; height: 2rem;">
                                    </v-progress-linear>
                                    <select :title="element.status"
                                        :style="{ 'color': (element.status == 'Concluído') ? 'var(--cor-bg)' : 'black' }"
                                        style="border: none; padding-left: 0.5rem;position: absolute; top: 0; left: 0rem; outline: none; border-radius: 25px ;height: 2rem ;text-align: center; background-color: transparent; padding-top: 0rem; padding-bottom: 0rem;"
                                        @change="editarBacklog('status', element.id, element.status)"
                                        v-model="element.status">
                                        <option value="" hidden></option>
                                        <option style="color: rgb(255, 145, 0);" value="Pendente">A iniciar</option>
                                        <option style="color: rgb(0, 47, 255);" :value="'Em andamento'">Em
                                            andamento
                                            0%</option>
                                        <option style="color: rgb(0, 47, 255);">Em andamento 25%</option>
                                        <option style="color: rgb(0, 47, 255);">Em andamento 50%</option>
                                        <option style="color: rgb(0, 47, 255);">Em andamento 75%</option>
                                        <option style="color: rgb(0, 192, 0);">Concluído</option>
                                    </select>
                                </div>
                            </td>

                            <td>
                                <v-menu>
                                    <template v-slot:activator="{ props }">
                                        <v-btn :id="'botaoEdicao' + element.id"
                                            style="visibility: hidden; width: 2rem; height: 2rem; background-color: transparent; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                            class="botaoAdicionarSprint acao-secundaria" icon="mdi-dots-horizontal"
                                            v-bind="props"></v-btn>
                                    </template>

                                    <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                        <v-list-item style="margin: 0.2rem;"
                                            @click="abrirModalEditarBacklog(element.id)">
                                            Editar Tarefa
                                        </v-list-item>
                                        <v-list-item>
                                            <v-menu>

                                                <template v-slot:activator="{ props }">
                                                    <span style="color: red;margin: 0.2rem; cursor: pointer;"
                                                        v-bind="props">Excluir
                                                        Tarefa</span>
                                                </template>

                                                <v-list
                                                    style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                    <v-list-item>
                                                        <div>
                                                            <h3>Tem certeza? Esta ação é Irreversível!</h3>
                                                            <div class="submit">
                                                                <Button
                                                                    @click="apagarBacklog(element.id)">Excluir</Button>
                                                                <button class="acao-secundaria">Cancelar</button>
                                                            </div>
                                                        </div>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </table>
                    <div style="display: flex; padding-left: 0.2rem; border-radius: 5px; width: 100%;"
                        :id="protocolo.id">
                        <div
                            style="border: 1px solid var(--cor-separador); border-radius: 5px; padding: 0.3rem; width: 6rem; align-content: center ;text-align: center;">
                            <strong>
                                {{ this.somenteBacklogs().length !== 0 ? 'Tarefa - ' +
                                    (parseInt((this.somenteBacklogs().codigo).match(/\d+$/)[0]) + 1) : 'Tarefa - 1' }}
                            </strong>
                        </div>
                        <div
                            style="border: 1px solid var(--cor-separador); border-radius: 5px; width: 100%; margin-left: 0.3rem; padding: 0.3rem;">
                            <input type="text" placeholder="O que será feito?"
                                @keyup.enter="criarBacklog(this.idProtocolo, $event.target.value), $event.target.value = ''"
                                style="width: 100%; padding: 0.1rem; padding-left: 0.5rem; outline: none; height: 2rem; border: none;"
                                :id="'inputNovaTarefa' + protocolo.id">
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL EDITAR BACKLOG-->
            <div class="modal-mask" v-if="showEditarBacklog" @click="fecharModalFora">
                <div class="jm" style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;">
                    <div>
                        <h3>Editar: {{ backlogeditado.codigo }} </h3>
                    </div>
                    <hr>
                    <br>
                    <div class="grid-2">
                        <div>
                            <label>Descrição</label>
                            <textarea v-model="backlogeditado.descricao"
                                @focusout="editarBacklog('descricao', backlogeditado.id, backlogeditado.descricao)"></textarea>
                        </div>

                        <div>
                            <label>Responsável</label>
                            <select v-model="backlogeditado.responsavel_id"
                                @change="editarBacklog('responsavel_id', backlogeditado.id, backlogeditado.responsavel_id)">
                                <option v-for="item in gerente" :key="item.id" :value="item.id">
                                    {{ item.nomeCompleto }}
                                </option>
                            </select>
                        </div>

                        <div class="grid-2">
                            <div>
                                <label>Inicio Previsto</label>
                                <input v-model="backlogeditado.dtInicio" type="date"
                                    @change="editarBacklog('dtInicio', backlogeditado.id, (backlogeditado.dtInicio + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                            </div>
                            <div>
                                <label>Fim Previsto</label>
                                <input v-model="backlogeditado.dtFim" type="date"
                                    @change="editarBacklog('dtFim', backlogeditado.id, (backlogeditado.dtFim + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                            </div>
                            <div>
                                <label>Inicio Real</label>
                                <input v-model="backlogeditado.dtInicioReal" type="date"
                                    @change="editarBacklog('dtInicioReal', backlogeditado.id, (backlogeditado.dtInicioReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                            </div>
                            <div>
                                <label>Fim Real</label>
                                <input v-model="backlogeditado.dtFimReal" type="date"
                                    @change="editarBacklog('dtFimReal', backlogeditado.id, (backlogeditado.dtFimReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                            </div>
                        </div>
                        <div>
                            <label>Observações</label>
                            <textarea v-model="backlogeditado.obs" rows="6"
                                @focusout="editarBacklog('obs', backlogeditado.id, backlogeditado.obs)"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <!--END MODAL -->
        </section>
    </main>
</template>

<script>
import { ref } from 'vue';
import { urlFoto } from "../../services/api";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import { sso } from "roboflex-thalamus-sso-lib";
import serviceFunções from "@/services/serviceFunções";

export default {
    name: "VisualizaçãoPlanodeAção",

    props: {
        idProtocolo: {
            required: true
        },
    },

    setup() {
        const fileInput = ref(null);

        const openFileInput = () => {
            fileInput.value.click();
        };

        const somarHP = (dados) => {
            return serviceFunções.somarHP2(dados);
        };

        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };

        return { fileInput, openFileInput, urlFoto, somarHP, nomeEsobrenome };
    },

    data() {
        return {
            inputText: '',

            protocolo: 'null',
            gerente: [],
            idUsuario: null,
            tipos: [],
            sprints: [],
            showEditarBacklog: false,
            anexos: null,

            teste: 'teste',

            setores: null
        }
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id
    },

    mounted() {
        this.getGerenteseSetor(),
            this.getProtocolo()
    },

    methods: {
        voltarHome() {
            this.$router.back();
        },

        handleFileUpload() {
            const fileInput = this.$refs.fileInput;

            if (fileInput && fileInput.files.length > 0) {
                const formData = new FormData();
                formData.append('protocolo_id', this.idProtocolo);

                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('anexos[]', fileInput.files[i]);
                }

                api.post(`/protocolo/atualizar/${this.idProtocolo}`, formData)
                    .then(response => {
                        this.anexos.push({
                            path: response.data.anexos_salvos[0].path,
                            nome: response.data.anexos_salvos[0].nome,
                            id: response.data.anexos_salvos[0].id
                        });
                        this.getProtocolo()
                    })
                    .catch(error => {
                        // Trata erros
                        console.error(error);
                    });
            }

        },

        getProtocolo() {
            api.get(`protocolo/buscar/${this.idProtocolo}`)
                .then((response) => {
                    this.protocolo = response.data.map(item => ({
                        ...item,
                        dtInicio: item.dtInicio ? item.dtInicio.slice(0, 10) : null,
                        dtFim: item.dtFim ? item.dtFim.slice(0, 10) : null,
                        tarefas: item.tarefas.map(item => ({
                            ...item,
                            dtInicio: item.dtInicio ? item.dtInicio.slice(0, 10) : null,
                            dtFim: item.dtFim ? item.dtFim.slice(0, 10) : null,
                            dtInicioReal: item.dtInicioReal ? item.dtInicioReal.slice(0, 10) : null,
                            dtFimReal: item.dtFimReal ? item.dtFimReal.slice(0, 10) : null,
                        })),
                    }));

                    this.anexos = response.data[0].anexos

                    this.protocolo = this.protocolo[0]
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores
            } catch (error) {
                console.error(error);
            }

            this.tipos = (await api.get('protocolo/tipo/listar')).data
        },

        excluirAnexo(id) {
            api.delete(`protocolo/anexo/remover/${id}`)
            this.anexos = this.anexos.filter(anexo => anexo.id !== id)
        },

        mostrarBotaoExcluirAnexo(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoExcluir' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoExcluir' + id).style.visibility = 'hidden'
            }
        },

        somenteBacklogs() {
            if (this.protocolo.tarefas && (this.protocolo.tarefas).length !== 0) {
                var teste = this.protocolo.tarefas
                return teste[teste.length - 1]
            }
            return ''
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        definirInicioFimReal(idTarefa, status) {

            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia

            if (status == 'Em andamento' || status == 'Em andamento 25%' || status == 'Em andamento 50%' || status == 'Em andamento 75%') {

                api.put(`protocolo/tarefa/atualizar/${idTarefa}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: data,
                    dtFimReal: null
                })
                    .then(() => {

                        return this.getProtocolo()
                    })

            } if (status == 'Concluído') {

                api.put(`protocolo/tarefa/atualizar/${idTarefa}`, {
                    usuario_id: this.idUsuario,
                    dtFimReal: data

                })
                    .then(() => {
                        return this.getProtocolo()
                    })

            } if (status == 'Pendente') {

                api.put(`protocolo/tarefa/atualizar/${idTarefa}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: null,
                    dtFimReal: null
                })
                    .then(() => {
                        return this.getProtocolo()
                    })

            }


        },

        abrirModalEditarBacklog(idProtocolo) {
            this.showEditarBacklog = true;
            this.backlogeditado = this.protocolo.tarefas.find(tarefa => tarefa.id === idProtocolo);
        },

        editarProtocolo(itemAlterado, novoValor) {

            api.post(`protocolo/atualizar/${this.idProtocolo}`, {
                usuario_id: this.idUsuario,
                [itemAlterado]: novoValor
            })
                .then(() => {
                    this.getProtocolo()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        editarBacklog(itemAlterado, idTarefa, novoValor) {

            api.put(`protocolo/tarefa/atualizar/${idTarefa}`, {
                usuario_id: this.idUsuario,
                [itemAlterado]: novoValor
                // + (itemAlterado == 'dtInicio' ? ' 00:00:00': null)
            })
                .then(() => {
                    if (itemAlterado == 'status') {
                        return this.definirInicioFimReal(idTarefa, novoValor)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.showEditarBacklog = false;
            }

        },

        fecharModal() {
            this.showIniciarSprint = false;
        },

        criarBacklog(id, descricao) {
            api.post(`protocolo/tarefa/gravar`, {
                codigo: this.somenteBacklogs().codigo ? 'Tarefa - ' + (parseInt((this.somenteBacklogs().codigo).match(/\d+$/)[0]) + 1) : 'Tarefa - 1',
                descricao: descricao,
                protocolo_id: id,
                status: 'Pendente'
            })
                .then((response) => {
                    localStorage.setItem('ultimaSprintEditada', id)
                    var novoItem = {
                        "id": response.data.id,
                        "sprint_id": id,
                        "codigo": this.somenteBacklogs().codigo ? 'Tarefa - ' + (parseInt((this.somenteBacklogs().codigo).match(/\d+$/)[0]) + 1) : 'Tarefa - 1',
                        "descricao": descricao,
                        "HP": 0,
                        "responsavel_id": 0,
                        "status": "Pendente",
                        "dtInicio": null,
                        "dtFim": null,
                        "dtInicioReal": null,
                        "dtFimReal": null,
                        "responsavel": null,
                        "anexos": []
                    };
                    this.protocolo.tarefas.push(novoItem)
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        apagarBacklog(idProtocolo) {

            api.post(`/protocolo/tarefa/excluir/${idProtocolo}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getProtocolo()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

    }
}
</script>

<style scoped>
.divAnexos {
    padding: 0.5rem;
    border: 1px solid var(--cor-separador);
    height: 3rem;
    border-radius: 6px;
    background-color: var(--cor-bg);
}
</style>